import { Auth } from 'aws-amplify';
import { deleteCookie, getCookies } from 'cookies-next';
import { EXPIRY, IDENTITY_ID } from '../../../utils/constants';
import { AmplifyChallengeNameError } from '../../../utils/error';
import { getSspControls } from '../../../utils/window';
import { ISspCredentials } from './../../../interfaces/index';



export const login = async (username: string, password: string) => {
  const loginResponse = await Auth.signIn(username, password);
  return loginResponse;
};
export const checkChallengeAmplify = (loginResponse: any) => {
  if (loginResponse?.challengeName)
    throw new AmplifyChallengeNameError(loginResponse.challengeName);
  return loginResponse;
};

export const hasValidSession = async (): Promise<boolean> => {
  try {
    const sspControls = getSspControls();
    if (sspControls && sspControls.provideCredentials?.()) return true;
    const expiration = localStorage.getItem(EXPIRY);
    const currentUnixTime = new Date().getTime() / 1000;
    const isExpired = !expiration || currentUnixTime > +expiration;
    if (isExpired) return false;
    const session = await Auth.currentSession();
    return session.isValid();
  } catch (error) {
    return false;
  }
};

export const cacheSspCredentials = (credentials: ISspCredentials) => {
  try {
    localStorage.setItem(IDENTITY_ID, credentials.identityId);
  } catch (e) {
    console.log('error when caching ssp credentials');
    console.error(e);
  }
};

export const clearCachedCredentials = () => {
  try {
    localStorage.removeItem(EXPIRY);
  } catch (e) {
    console.log('error when clearing cached credentials');
    console.error(e);
  }
};

export const cacheCredentials = async () => {
  try {

    // fix XXX
    // const credentials = await Auth.currentCredentials();

    // const currentIdentityId = localStorage.getItem(IDENTITY_ID);

    //localStorage.setItem(IDENTITY_ID, credentials.identityId);
    
    const session = await Auth.currentSession();
    const tokenExpiration = session.getAccessToken().getExpiration();
    localStorage.setItem(EXPIRY, tokenExpiration.toString());
  } catch (e) {
    console.log('error when caching credentials');
    console.error(e);
  }
};


export const clearCognitoTokens = () => {
  Object.keys(getCookies()).forEach((k) => {
    if (k.includes('CognitoIdentityServiceProvider')) {
      deleteCookie(k);
    }
  });
  Object.keys(localStorage).forEach((k) => {
    if (k.includes('CognitoIdentityServiceProvider')) {
      localStorage.removeItem(k);
    }
  });
};

export const signOut = () => {
  clearCognitoTokens();
  return Auth.signOut().then(clearCachedCredentials);
};
