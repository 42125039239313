import AWS from 'aws-sdk';
import { appDomain, assetBaseUrl, region, s3BaseUrl } from '../config';
import { IProduct } from '../interfaces';
import { getCredentials } from '../services/aws/apigClient';

const getKeyAndBucketFromUrl = (url: string) => {
  if (url.startsWith(assetBaseUrl)) {
    return {
      bucket: 'storiez-assets',
      key: url.replace(assetBaseUrl + '/', '')
    };
  }
};

export const bucketKeyExtractor = (imageUrl: string) => {
  if (imageUrl.startsWith('https://')) {
    return getKeyAndBucketFromUrl(imageUrl);
  }
  const components = imageUrl.split('/');
  const bucket = components[0];
  components.shift();
  const key = components.join('/');
  return { key, bucket };
};

export const basePathAndIdExtractor = (imageUrl: string) => {
  const components = imageUrl.split('/');
  const id = components[2];
  components.pop();
  return { id, basePath: components.join('/') };
};

export const getWatchStrapImagesUrl = (imageUrl: string) => {
  const { id, basePath } = basePathAndIdExtractor(imageUrl);

  return [
    toS3Url(`${basePath}/straps/${id}_top.png`),
    toS3Url(`${basePath}/straps/${id}_bottom.png`),
    toS3Url(`${basePath}/straps/${id}_large.png`)
  ];
};

export const getWatchBuckleImagesUrl = (imageUrl: string) => {
  const { id, basePath } = basePathAndIdExtractor(imageUrl);
  return [
    toS3Url(`${basePath}/buckles/${id}_small.png`),
    toS3Url(`${basePath}/buckles/${id}_large.png`)
  ];
};

export const getWatchDialImagesUrl = (imageUrl: string) => {
  const components = imageUrl.split('%2F');
  if (components.length >= 2)
    return `${components[0]}/${components[1]}/dial/${components[1]}_dial.png`;
};

const encodeKeyInUrl = (imageUrl: string) => {
  const components = imageUrl.split('/');
  return components
    .map((c) => {
      if (c.indexOf('#') > -1 || c.indexOf('@') > -1) {
        return encodeURIComponent(c);
      }
      return c;
    })
    .join('/');
};

export const toS3Url = (imageUrl?: string) => {
  if (!imageUrl) {
    return null;
  }
  if (imageUrl.startsWith('https')) {
    return encodeKeyInUrl(imageUrl);
  }
  const { key, bucket } = bucketKeyExtractor(imageUrl);
  if (bucket === 'storiez-products') {
    return `https://products.${appDomain}/${encodeURIComponent(key)}`;
  }
  if (bucket === 'storiez-campaignpics') {
    return `https://stories.${appDomain}/${encodeURIComponent(key)}`;
  }
  if (bucket === 'storiez-assets') {
    return `https://assets.${appDomain}/${encodeURIComponent(key)}`
      .replace('assets.inspify.io', 'assets.inspify.com')
      .replace('videos.inspify.io', 'videos.inspify.com');
  }
  return `https://${bucket}.${s3BaseUrl}/${encodeURIComponent(key)}`
    .replace('assets.inspify.io', 'assets.inspify.com')
    .replace('videos.inspify.io', 'videos.inspify.com');
};

export const getProductOGImageUrl = (product: IProduct) =>
  `https://products.${appDomain}/${product.brandId}/${product.id}/1000x1000/${product.id}_001.jpeg`;

export const toWebResolution = (imageUrl: string) => {
  const components = imageUrl.split('/');
  const fixedParts = components.slice(0, components.length - 1);
  const lastPart = components[components.length - 1]
    .replace('.jpg', '.png')
    .replace('.jpeg', '.png');
  const result = [...fixedParts, '1000x1000', lastPart].join('/');
  return result;
};

export const getS3Instance = (): Promise<AWS.S3> => {
  return getCredentials().then(
    (credentials) =>
      new AWS.S3({
        region,
        credentials
      })
  );
};

export const sessionConvertedFileSigner = async () => {
  try {
    const s3 = await getS3Instance();
    return (file: string) => {
      const { key, bucket } = bucketKeyExtractor(file);
      const signedUrlExpireSeconds = 60 * 60 * 24;
      return s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key,
        Expires: signedUrlExpireSeconds
      });
    };
  } catch (e) {
    console.log(e);
    return toS3Url;
  }
};

export const assetUrlSigner = async () => {
  try {
    const s3 = await getS3Instance();
    return (url: string) => {
      const { key, bucket } = bucketKeyExtractor(url);
      const signedUrlExpireSeconds = 60 * 60 * 24;
      return s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key,
        Expires: signedUrlExpireSeconds
      });
    };
  } catch (e) {
    console.log(e);
  }
};

export const urlSigner = async () => {
  try {
    const s3 = await getS3Instance();
    return (id: string) => {
      const imageUrl = `storiez-users/${id}/avatar.jpg`;
      const { key, bucket } = bucketKeyExtractor(imageUrl);
      const signedUrlExpireSeconds = 60 * 60 * 24;
      return s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key,
        Expires: signedUrlExpireSeconds
      });
    };
  } catch (e) {
    console.log(e);
    return getBrandAvatarUrl;
  }
};

export const getBrandAvatarUrl = (brandId: string) =>
  `https://storiez-brands.${s3BaseUrl}/${brandId}/avatar.jpg`;

export const downloadAndResizeImage = async (
  url,
  maxWidth,
  maxHeight
): Promise<any> => {
  const response = await fetch(url);
  const imageBlob = await response.blob();
  const image = new Image();
  image.src = URL.createObjectURL(imageBlob);
  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
  });
  const scalingFactor = Math.min(
    maxWidth / image.width,
    maxHeight / image.height
  );
  const canvas = document.createElement('canvas');
  canvas.width = image.width * scalingFactor;
  canvas.height = image.height * scalingFactor;
  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      0.8
    );
  });
};

export const isHexColorTooBright = (hexColor: string) => {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!hexRegex.test(hexColor)) {
    return false;
  }
  const c = hexColor.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma > 90;
};
