import { Amplify, Auth } from 'aws-amplify';
import apigClientFactory from 'aws-api-gateway-client';
import AWS from 'aws-sdk';
import { setCookie } from 'cookies-next';
import moment from 'moment';
import { getSspControls } from '../../../utils/window';
import { baseUrl, region } from '../../config';
import amplify from '../../utils/amplify';
import { clearCognitoTokens } from '../login';
import { SspControls } from './../../../utils/window';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: amplify.cognito.REGION,
    userPoolId: amplify.cognito.USER_POOL_ID,
    identityPoolId: amplify.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: amplify.cognito.APP_CLIENT_ID
  },
  ssr: true
});


export const getCredentials = async () => {
  let credentials: any;
  const sspControls: SspControls = getSspControls();
  if (sspControls && sspControls.provideCredentials?.())
    try {
      credentials = JSON.parse(sspControls.getCredentials());
      const idToken = credentials.idToken;
      const accessToken = credentials.accessToken;
      const lastAuthUser = credentials.lastAuthUser;
      const refreshToken = credentials.refreshToken;
      const deviceKey = credentials.deviceKey;
      const identityId = credentials.identityId;
      const session = {
        'IDENTITY_ID': identityId,
        ['CognitoIdentityServiceProvider.' + amplify.cognito.APP_CLIENT_ID + '.' + lastAuthUser + '.accessToken']: accessToken,
        ['CognitoIdentityServiceProvider.' + amplify.cognito.APP_CLIENT_ID + '.' + lastAuthUser + '.idToken']: idToken,
        ['CognitoIdentityServiceProvider.' + amplify.cognito.APP_CLIENT_ID + '.LastAuthUser']: lastAuthUser,
        ['CognitoIdentityServiceProvider.' + amplify.cognito.APP_CLIENT_ID + '.' + lastAuthUser + '.refreshToken']: refreshToken,
        ['CognitoIdentityServiceProvider.' + amplify.cognito.APP_CLIENT_ID + '.' + lastAuthUser + '.deviceKey']: deviceKey
      };

      clearCognitoTokens()

      Object.entries(session).forEach(([key, val]) => {
        if(val) {
          localStorage.setItem(key, decodeURIComponent(val));
          setCookie(key, val, {
            sameSite: 'strict',
            expires: moment().add(3, 'hour').toDate()
          });
        }
      });

    } catch (error) {
      console.log('Fail to parse Ssp credentials JSON');
      return Promise.reject(error);
    }
  else credentials = await Auth.currentCredentials();
  

  return {
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  };
};



export const getS3Instance = async (): Promise<AWS.S3> => {
  const credentials = await getCredentials();
  return new AWS.S3({
    region,
    credentials: {
      accessKeyId: credentials.accessKey as string,
      secretAccessKey: credentials.secretKey as string,
      sessionToken: credentials.sessionToken as string
    }
  });
};

export const apigClient = async (url?: string) => {
  const config = {
    region,
    invokeUrl: url || baseUrl,
    defaultContentType: 'application/json',
    defaultAcceptType: 'application/json'
  };

  const credentials = await getCredentials();

  
  return apigClientFactory.newClient({
    ...config,
    ...credentials
  });
};



export const getObjectUrlFromS3 = (
  Bucket: string,
  Key: string,
  fileName?: string
) => {
  const signedUrlExpireSeconds = 60 * 60 * 24;
  return getS3Instance().then((s3) =>
    s3.getSignedUrl('getObject', {
      Bucket,
      Key,
      Expires: signedUrlExpireSeconds,
      ResponseContentDisposition: fileName
        ? `attachment; filename=${fileName}`
        : undefined
    })
  );
};

export const getObjectFromS3 = (bucket: string, key: string) => {
  return getS3Instance().then((s3) =>
    s3
      .getObject({
        Bucket: bucket,
        Key: key
      })
      .promise()
  );
};

export const deleteObjectFromS3 = (bucket: string, key: string) => {
  return getS3Instance().then((s3) =>
    s3
      .deleteObject({
        Bucket: bucket,
        Key: key
      })
      .promise()
  );
};

export const uploadToS3 = ({
  Body,
  Bucket,
  Key,
  ACL,
  ContentType,
  ContentEncoding
}: {
  Body: File | Buffer;
  Bucket: string;
  Key: string;
  ACL?: string;
  ContentType?: string;
  ContentEncoding?: string;
}) => {
  const payload = {
    Bucket,
    Key,
    Body,
    ACL,
    ContentType,
    ContentEncoding
  };
  return getS3Instance().then((s3) => s3.upload(payload).promise());
};

export const copyS3Object = ({
  Bucket,
  CopySource,
  Key,
  ACL
}: {
  Bucket: string;
  CopySource: string;
  Key: string;
  ACL?: string;
}) => {
  const payload = {
    Bucket,
    CopySource,
    Key,
    ACL
  };
  return getS3Instance().then((s3) => s3.copyObject(payload).promise());
};

//function to get list of objects from s3, grouped by folder
export const listObjectsFromS3 = ({
  Bucket,
  Prefix,
  Delimiter
}: {
  Bucket: string;
  Prefix?: string;
  Delimiter?: string;
}) => {
  const payload = {
    Bucket,
    Prefix,
    Delimiter
  };
  return getS3Instance().then((s3) => s3.listObjectsV2(payload).promise());
};


export const uploadBase64ToS3 = ({
  Body,
  Bucket,
  Key,
  ACL,
  ContentType
}: {
  Body: string;
  Bucket: string;
  Key: string;
  ACL?: string;
  ContentType?: string;
}) => {
  const buf = Buffer.from(
    Body.replace(/^data:image\/\w+;base64,/, ''),
    'base64'
  );

  const payload = {
    Bucket,
    Key,
    Body: buf,
    ContentType,
    ACL,
    ContentEncoding: 'base64'
  };
  return getS3Instance().then((s3) => s3.putObject(payload).promise());
};

export const decodeS3FileName = (key) => {
  return decodeURIComponent(key.replace(/\+/g, ' '));
};

