import { chunk, flatten } from 'lodash';
import pLimit from 'p-limit';
import { StoreType } from 'polotno/model/store';
import { TDeviceView } from '../advisorHub/components/Common/DeviceViewToggle';
import {
  ILibraryScene,
  ISceneLayer,
  IStorybook,
  IStorybookPage,
  SceneLayerSettings
} from '../interfaces';
import { isVideo } from '../utils/file';
import { generateV4UUID } from '../utils/identityGenerator';
import {
  generatedImageFromServer,
  removeUnusedFontsInPage
} from '../utils/polotno';
import { extractPageId, getPolotnoStore } from '../utils/window';
import { isGifUrl } from './../utils/file';

// Adjust the import path as neede
import { translatePolotnoElement } from '../advisorHub/utils/translationHelper';

export const POLOTNO_PAGE_WIDTH = 1920;
export const POLOTNO_PAGE_HEIGHT = 1080;

export interface SectionTabProps {
  children: any;
  name: string;
  onClick: any;
  active: boolean;
  iconSize?: number;
  displayName: string;
}

const imageElement = (payload: any) => ({
  id: 'gSgzjTLO-D',
  type: 'image',
  name: '',
  x: 0,
  y: 0,
  rotation: 0,
  opacity: 1,
  blurEnabled: false,
  blurRadius: 10,
  brightnessEnabled: false,
  brightness: 0,
  sepiaEnabled: false,
  grayscaleEnabled: false,
  shadowEnabled: false,
  shadowBlur: 5,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowColor: 'black',
  shadowOpacity: 1,
  visible: true,
  draggable: true,
  selectable: true,
  contentEditable: true,
  styleEditable: true,
  alwaysOnTop: false,
  showInExport: true,
  width: 512,
  height: 288,
  src: '',
  cropX: 0,
  cropY: 0,
  cropWidth: 1,
  cropHeight: 1,
  cornerRadius: 0,
  flipX: false,
  flipY: false,
  clipSrc: '',
  borderColor: 'black',
  borderSize: 0,
  ...payload
});

export interface CustomPanel {
  name: string;
  Tab: ((props: SectionTabProps) => JSX.Element) & {
    displayName: string;
  };
  Panel: ({ store }: { store: any }) => JSX.Element;
}

const flattenChildren = (children: any[], arrHolder = []) => {
  for (let i = 0; i < children.length; i++) {
    arrHolder.push(children[i]);
    if (children[i].children) {
      flattenChildren(children[i].children, arrHolder);
    }
  }
  return arrHolder;
};

const isVideoElement = (el: any) => !!el?.custom?.videoUrl;

const isProductElement = (el: any) => !!el?.custom?.productId;

export const getPolotnoVideoElement = (children: any[]) => {
  const flatten = flattenChildren(children);
  return flatten.filter(isVideoElement);
};

export const getPolotnoProductElement = (children: any[]) => {
  const flatten = flattenChildren(children);
  return flatten.filter(isProductElement);
};

const getPolotnoVideoElementInPage = (activePage: IPolotnoPage) =>
  getPolotnoVideoElement(activePage?.children);

const getPolotnoProductElementInPage = (activePage: IPolotnoPage) =>
  getPolotnoProductElement(activePage?.children);
const isActionElement = (el: any) => !!el?.custom?.action;

export const getPolotnoActionElement = (children: any[]) => {
  const flatten = flattenChildren(children);
  return flatten.filter(isActionElement);
};

export const getPolotnoActionElementInPage = (activePage: IPolotnoPage) =>
  getPolotnoActionElement(activePage?.children);

const isGifElement = (el: any) => isGifUrl(el?.src);

export const getPolotnoGifElement = (children: any[]) => {
  const flatten = flattenChildren(children);
  return flatten.filter(isGifElement);
};

export const getPolotnoGifElementInPage = (activePage: IPolotnoPage) =>
  getPolotnoGifElement(activePage?.children);

export const getSelectedElementsType = (
  elements: any[]
): 'video' | 'gif' | 'auto' => {
  if (getPolotnoVideoElement(elements).length) return 'video';
  if (getPolotnoGifElement(elements).length) return 'gif';
  return 'auto';
};

export type TLanguage =
  | 'en'
  | 'ja'
  | 'kr'
  | 'cn'
  | 'hk'
  | 'de'
  | 'fr'
  | 'th'
  | 'it'
  | 'es';

export const LANGUAGE_VARIATIONS: TLanguage[] = [
  'en',
  'ja',
  'kr',
  'cn',
  'hk',
  'de',
  'fr',
  'th',
  'it',
  'es'
];
export interface IPolotnoPage {
  id?: string;
  children?: any[];
  width?: string | number;
  height?: string | number;
  background?: string;
  bleed?: number;
}

export interface IPolotnoJSON {
  width: number;
  height: number;
  fonts?: any[];
  pages: IPolotnoPage[];
  dpi?: number;
}

export const mapSceneToPolotnoElement = (scene: ILibraryScene) => {
  return {
    id: generateV4UUID(),
    src: scene.content.url,
    type: 'image',
    visible: true,
    width: POLOTNO_PAGE_WIDTH / 2,
    height: POLOTNO_PAGE_HEIGHT / 2,
    x: POLOTNO_PAGE_WIDTH / 4,
    y: POLOTNO_PAGE_HEIGHT / 4
  };
};

export const generatePageDimension = (portrait: boolean) => [
  portrait ? POLOTNO_PAGE_HEIGHT : POLOTNO_PAGE_WIDTH,
  portrait ? POLOTNO_PAGE_WIDTH : POLOTNO_PAGE_HEIGHT
];

export const mapStorybookPageToPolotnoJson = (page: IStorybookPage) => {
  return {
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    fonts: [],
    pages: [
      mapStorybookPageToPolotnoPage(page),
      mapStorybookPageToPolotnoPage(page, true)
    ]
  };
};

const isNotBlankBackground = (page: IPolotnoPage) =>
  page?.background &&
  page?.background !== 'white' &&
  page?.background !== '#ffffff';

export const isPolotnoPageHasContent = (page: IPolotnoPage) => {
  return page?.children?.length > 0 || isNotBlankBackground(page);
};

export const checkForMissingPolotnoPage = (
  content: IPolotnoJSON
): TDeviceView => {
  if (!content) return;
  const hasNoLandscape = !isPolotnoPageHasContent(content?.pages?.[0]);
  const hasNoPortrait = !isPolotnoPageHasContent(content?.pages?.[1]);
  if (hasNoLandscape && hasNoPortrait) {
    return 'compare';
  }
  if (hasNoLandscape) {
    return 'desktop';
  }
  if (hasNoPortrait) {
    return 'mobile';
  }
};

const generatePageId = (id, portrait) => (portrait ? `${id}-portrait` : id);

export const mapSceneToPolotnoPage = (
  scene: ILibraryScene,
  portrait?: boolean
): IPolotnoPage => {
  const { id, content } = scene;
  const [width, height] = generatePageDimension(portrait);
  const bleed = portrait ? 0 : 0;
  return {
    id: generatePageId(id, portrait),
    bleed,
    children: [],
    height,
    width,
    background: isVideo(content?.url)
      ? content?.thumbnail
      : content?.url || '#ffffff'
  };
};

export const mapStorybookPageToPolotnoPage = (
  sbPage: IStorybookPage,
  portrait?: boolean
): IPolotnoPage => {
  const { id, url } = sbPage;
  const [width, height] = generatePageDimension(portrait);
  return {
    id,
    bleed: 0,
    children: [],
    height,
    width,
    background: encodeURI(url)
  };
};

export const mapSceneToPolotnoJson = (page: ILibraryScene) => {
  return {
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    fonts: [],
    pages: [mapSceneToPolotnoPage(page), mapSceneToPolotnoPage(page, true)]
  };
};

export const cloneLandScapeToPortrait = (page: IPolotnoPage) => {
  const [width, height] = generatePageDimension(true);
  return {
    ...page,
    width,
    height,
    children: page?.children?.map((child) => ({
      ...child,
      width: child.width / 1.77,
      height: child.height / 1.77,
      fontSize: child.fontSize / 1.77,
      x: child.x / 1.77,
      y: child.y / 1.77,
      id: generateV4UUID()
    })),
    id: `${page.id}-portrait`
  };
};

export const cloneDefaultToNewLanguage = async (
  sceneId: string,
  page: IPolotnoPage,
  language: TLanguage,
  isPortrait?: boolean
) => {
  const [width, height] = generatePageDimension(isPortrait);

  console.log('Cloning page for language:', language);

  const translateElement = async (element: any): Promise<any> => {
    try {
      const translatedElement = await translatePolotnoElement(
        element,
        language
      );
      if (element.children && element.children.length > 0) {
        translatedElement.children = await Promise.all(
          element.children.map(translateElement)
        );
      }
      return {
        ...translatedElement,
        id: generateV4UUID()
      };
    } catch (error) {
      console.error('Error translating element:', element, error);
      throw error;
    }
  };

  const cloneAndTranslateChildren = async (children: any[]): Promise<any[]> => {
    try {
      return await Promise.all(children.map(translateElement));
    } catch (error) {
      console.error('Error translating children:', error);
      throw error;
    }
  };

  try {
    const translatedChildren = await cloneAndTranslateChildren(
      page?.children || []
    );

    return {
      ...page,
      width,
      height,
      children: translatedChildren,
      id: isPortrait
        ? `${sceneId}-${language}-portrait`
        : `${sceneId}-${language}`
    };
  } catch (error) {
    console.error('Error cloning and translating page:', page, error);
    throw error;
  }
};

export const getPageByPageId = (json, id: string) => {
  const pages = json.pages || [];
  return pages.find((p) => p.id === id);
};

export const generateBlankPolotnoPage = (
  id: string,
  portrait?: boolean
): IPolotnoPage => {
  const [width, height] = generatePageDimension(portrait);
  return {
    id: generatePageId(id, portrait),
    bleed: 0,
    children: [],
    height,
    width
  };
};

export const blankPolotnoJson = (initId?: string): IPolotnoJSON => {
  const id = initId || generateV4UUID();
  return {
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    fonts: [],
    pages: [generateBlankPolotnoPage(id), generateBlankPolotnoPage(id, true)]
  };
};

export const blankLanguageFeaturedImageJson = (
  featuredImageJson
): IPolotnoJSON => {
  const [width, height] = [1024, 576];

  const generateChildren = (src) => {
    const element = imageElement({
      id: generateV4UUID(),
      src: src,
      width,
      height,
      name: 'Featured Image'
    });
    return [element];
  };
  return {
    width,
    height,
    dpi: 96,
    fonts: [],
    pages: Object.keys(featuredImageJson).map((language: TLanguage) => {
      return {
        id: `${generateV4UUID()}${language === 'en' ? '' : `-${language}`}`,
        bleed: 0,
        children: generateChildren(featuredImageJson[language]),
        width,
        height
      };
    })
  };
};

export const blankFeaturedImageJson = (
  featuredImage?: string
): IPolotnoJSON => {
  const [width, height] = [1024, 576];
  const children = featuredImage
    ? [
        imageElement({
          id: generateV4UUID(),
          src: featuredImage,
          width,
          height,
          name: 'Featured Image'
        })
      ]
    : [];
  return {
    width,
    height,
    dpi: 96,
    fonts: [],
    pages: [
      {
        id: generateV4UUID(),
        bleed: 0,
        children,
        width,
        height
      }
    ]
  };
};

export const mapStorybookToPolotnoJson = (sb: IStorybook) => {
  const pages = sb.pages.map((page) => mapStorybookPageToPolotnoPage(page));
  return {
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    fonts: [],
    pages
  };
};

export const signPolotnoJson =
  (urlSigner?: (url: string) => string) =>
  (json: IPolotnoJSON): IPolotnoJSON => {
    const { pages } = json;
    const signedPages = pages.map((page) => {
      const { children } = page;
      const signedChildren = children.map((child) => {
        const { src } = child;
        return {
          ...child,
          src: src?.startsWith('https://assets.inspify') ? urlSigner(src) : src
        };
      });
      return {
        ...page,
        children: signedChildren
      };
    });
    return {
      ...json,
      pages: signedPages
    };
  };

export const appendPolotnoJsonIntoStorybook = (
  storybook: IStorybook,
  json: IPolotnoJSON
): IStorybook => {
  const { pages } = json;
  const { pages: sbPages } = storybook;

  const newSbPages = sbPages.map((sbPage) => {
    const polotnoPage = pages.find((p) => p.id === sbPage.id);
    const polotnoPagePortrait = pages.find(
      (p) => p.id === `${sbPage.id}-portrait`
    );
    const polotnoJson = {
      ...blankPolotnoJson(),
      pages: polotnoPagePortrait
        ? [polotnoPage, polotnoPagePortrait]
        : [polotnoPage]
    };

    if (polotnoPage && !sbPage.overlay) {
      return {
        ...sbPage,
        overlay: JSON.stringify(polotnoJson),
        editor: polotnoJson
      };
    }
    return sbPage;
  });

  return {
    ...storybook,
    pages: newSbPages
  };
};

export const getPolotnoJsonFromStorybook = (storybook: IStorybook) => {
  const pages =
    storybook?.pages
      ?.filter((p) => p.editor || p.overlay)
      .map((p2) => p2?.editor?.pages || JSON.parse(p2.overlay).pages)
      .flat() || [];

  return {
    ...blankPolotnoJson(),
    pages
  };
};

const clearBackgroundInPages = (pages: IPolotnoPage[]) => {
  if (pages.length === 1) {
    return pages;
  }
  return pages.map((page, idx) =>
    idx === 0
      ? page
      : {
          ...page,
          background: undefined
        }
  );
};

const applyUniqueLayerIdToPage = (pages: IPolotnoPage[]) => {
  return pages.map((page) => ({
    ...page,
    id: generateV4UUID(),
    children: page.children.map((child) => ({
      ...child,
      id: generateV4UUID()
    }))
  }));
};
const cleanupLayers = (pages: IPolotnoPage[]) => {
  return applyUniqueLayerIdToPage(clearBackgroundInPages(pages));
};

const extractLayer = (page: IPolotnoPage) => {
  if (!page) return;
  const hasGifElements = !!getPolotnoGifElementInPage(page)?.length;
  const hasVideoElements = !!getPolotnoVideoElementInPage(page)?.length;
  const hasProductElements = !!getPolotnoProductElementInPage(page)?.length;
  const hasLayerElements =
    hasGifElements || hasVideoElements || hasProductElements;

  const backgroundOnlyPage = {
    ...page,
    id: generateV4UUID(),
    children: []
  };

  const firstLayer = isNotBlankBackground(page) ? [backgroundOnlyPage] : [];
  const children = flattenChildren(page.children);

  //if there is no video element, has image, and page is transparent. Return page as it is
  if (
    !hasLayerElements ||
    (hasLayerElements && children.length === 1 && !isNotBlankBackground(page))
  ) {
    return [...firstLayer, { ...page, id: generateV4UUID() }];
  }

  //if there is a video element only and the page has a background, return the page with the background and video
  if (hasLayerElements && children.length === 1 && isNotBlankBackground(page)) {
    return cleanupLayers([
      ...firstLayer,
      { ...page, id: generateV4UUID(), background: undefined }
    ]);
  }

  //difficult part: layered video and image
  //layer  = list of pages grouped by children type (image/video/product)

  const getLayerType = (el) =>
    isVideoElement(el)
      ? 'video'
      : isGifElement(el)
      ? 'gif'
      : isProductElement(el)
      ? 'product'
      : 'image';

  const arrGroups = children.reduce((prev, curr) => {
    if (!prev?.length) {
      prev.push([curr]);
    } else {
      const prevType = getLayerType(prev[prev.length - 1][0]);
      const currType = getLayerType(curr);
      if (prevType === currType && currType === 'image') {
        prev[prev.length - 1].push(curr);
      } else {
        prev.push([curr]);
      }
    }
    return prev;
  }, []);

  const layer = arrGroups.map((group) => ({
    ...page,
    children: group
  }));

  return cleanupLayers([...firstLayer, ...layer]);
};

export const getSceneLayer = async (store: any, scene?: ILibraryScene) => {
  if (!store) return;
  const missingPage = checkForMissingPolotnoPage(store);

  if (missingPage === 'compare' || missingPage === 'desktop') return;

  const layers = {
    desktop: {
      value: extractLayer(store.pages[0]),
      needGenerateLayer: true
    },
    mobile: {
      value: isPolotnoPageHasContent(store.pages[1])
        ? extractLayer(store.pages[1])
        : undefined,
      needGenerateLayer: true
    }
  };
  if (!scene) return layers;

  const pages = JSON.parse(scene.content.overlay)?.pages || [];

  if (pages.length) {
    pages.map((page) => {
      const { language, isPortrait } = extractPageId(page.id);
      const key = `${isPortrait ? 'mobile' : 'desktop'}${
        language === 'en' ? '' : `-${language}`
      }`;
      if (isPortrait) {
        const hasContent = isPolotnoPageHasContent(page);
        layers[key] = {
          value: hasContent ? extractLayer(page) : undefined,
          needGenerateLayer: hasContent
        };
      } else {
        layers[key] = {
          value: extractLayer(page),
          needGenerateLayer: true
        };
      }
    });
  }
  return layers;
};

export const getSceneLayerForPreview = (store: any, scene?: ILibraryScene) => {
  if (!store && !scene) return;
  const missingPage = checkForMissingPolotnoPage(store);

  if (missingPage === 'compare' || missingPage === 'desktop') return;
  const layers = {
    desktop: {
      value: extractLayer(store.pages[0]),
      needGenerateLayer: true
    },
    mobile: {
      value: isPolotnoPageHasContent(store.pages[1])
        ? extractLayer(store.pages[1])
        : undefined,
      needGenerateLayer: true
    }
  };
  if (!scene) return layers;

  const pages = JSON.parse(scene.content.overlay)?.pages || [];
  if (pages.length) {
    pages.map((page) => {
      const { language, isPortrait } = extractPageId(page.id);
      const key = `${isPortrait ? 'mobile' : 'desktop'}${
        language === 'en' ? '' : `-${language}`
      }`;
      if (isPortrait) {
        layers[key] = {
          value: isPolotnoPageHasContent(page) ? extractLayer(page) : undefined,
          needGenerateLayer: true
        };
      } else {
        layers[key] = {
          value: extractLayer(page),
          needGenerateLayer: true
        };
      }
    });
  }
  return layers;
};

const getFontUrl = (fontArray, fontFamily) => {
  return fontArray.find((font) => font.fontFamily === fontFamily)?.url;
};

export const mapLayersToLayerSetting = (
  layers: IPolotnoPage[],
  renderedResult: string[],
  store: StoreType
): SceneLayerSettings[] => {
  const layerToBeRendered = layers.map((page, idx) =>
    isVideoElement(page.children[0])
      ? {
          ...page.children[0].custom,
          type: 'video',
          width: page.children[0].width,
          height: page.children[0].height,
          x: page.children[0].x,
          y: page.children[0].y,
          url: page.children[0].custom.videoUrl,
          transparent: idx !== 0,
          opacity: page.children[0].opacity
        }
      : isProductElement(page.children[0])
      ? {
          ...page.children[0],
          type: 'product',
          product: {
            id: page.children[0].custom.productId,
            field: page.children[0].custom.productField,
            defaultValue:
              page.children[0].type === 'image'
                ? page.children[0].src
                : page.children[0].text,
            type: page.children[0].type
          },
          fontUrl: getFontUrl(
            store.toJSON().fonts,
            page.children[0].fontFamily
          ),
          transparent: idx !== 0
        }
      : isGifElement(page.children[0])
      ? {
          ...page.children[0].custom,
          type: 'gif',
          width: page.children[0].width,
          height: page.children[0].height,
          x: page.children[0].x,
          y: page.children[0].y,
          url: page.children[0].src,
          transparent: idx !== 0,
          opacity: page.children[0].opacity,
          flipX: page.children[0].flipX,
          flipY: page.children[0].flipY,
          rotation: page.children[0].rotation
        }
      : {
          type: 'image',
          url: renderedResult[idx],
          transparent: idx !== 0,
          width: page.width === 'auto' ? store.width : page.width,
          height: page.height === 'auto' ? store.height : page.height
        }
  );

  const linkLayers = layers.map(getPolotnoActionElementInPage).flat();

  const mappedLinkLayers = linkLayers.map((layer) => ({
    type: 'action',
    width: layer.width,
    height: layer.height,
    x: layer.x,
    y: layer.y,
    action: layer.custom.action
  }));

  return [...layerToBeRendered, ...mappedLinkLayers];
};

const generateLayersJson = (store: StoreType, layers: IPolotnoPage[]) => {
  return layers.map((page) => {
    return {
      ...store.toJSON(),
      pages: [{ ...page }]
    };
  });
};
export const editorJsonBucket = 'storiez-library';
export const editorImageBucket = 'storiez-assets';
export const renderLayers = async (
  layers: any[],
  store: StoreType,
  keyBase?: string
): Promise<{
  mapped: SceneLayerSettings[];
  result: string[];
}> => {
  const layersJSON = generateLayersJson(store, layers);
  const fileExtension = (transparent: boolean) =>
    transparent ? 'png' : 'jpeg';
  const fileName = (i: number, transparent: boolean) =>
    `${keyBase}layer_${i + 1}.${fileExtension(transparent)}`;

  const layersPayload = layersJSON.map((json, i) => {
    const isCustomElement =
      isVideoElement(json.pages?.[0]?.children?.[0]) ||
      isGifElement(json.pages?.[0]?.children?.[0]);
    if (isCustomElement) return null;

    const transparent = i !== 0;
    const fileType = transparent ? 'image/png' : 'image/jpeg';

    return {
      json: removeUnusedFontsInPage(json),
      exportOptions: {
        pixelRatio: 1.5,
        mimeType: fileType,
        ignoreBackground: i !== 0
      },
      uploadOptions: keyBase
        ? {
            bucket: editorImageBucket,
            Key: fileName(i, transparent),
            ContentType: i === 0 ? 'image/jpeg' : 'image/png'
          }
        : undefined
    };
  });

  const rendered = await generatedImageFromServer(layersPayload);

  const mapped = mapLayersToLayerSetting(layers, rendered, store);
  return {
    mapped,
    result: rendered
  };
};

export const renderMultiplePartLayers = async (
  parts: { layers: any[]; store: StoreType; keyBase?: string }[]
): Promise<{
  mapped: SceneLayerSettings[];
  result: string[];
}> => {
  const layersPayload = parts.flatMap((part) => {
    const { layers, store, keyBase } = part;
    const layersJSON = generateLayersJson(store, layers);
    const fileExtension = (transparent: boolean) =>
      transparent ? 'png' : 'jpeg';
    const fileName = (i: number, transparent: boolean) =>
      `${keyBase}layer_${i + 1}.${fileExtension(transparent)}`;

    return layersJSON.map((json, i) => {
      const isCustomElement =
        isVideoElement(json.pages?.[0]?.children?.[0]) ||
        isGifElement(json.pages?.[0]?.children?.[0]);
      if (isCustomElement) return null;

      const transparent = i !== 0;
      const fileType = transparent ? 'image/png' : 'image/jpeg';

      return {
        json: removeUnusedFontsInPage(json),
        exportOptions: {
          pixelRatio: 1.5,
          mimeType: fileType,
          ignoreBackground: i !== 0
        },
        uploadOptions: keyBase
          ? {
              bucket: editorImageBucket,
              Key: fileName(i, transparent),
              ContentType: i === 0 ? 'image/jpeg' : 'image/png'
            }
          : undefined
      };
    });
  });
  const limit = pLimit(3);
  const layersChunks = chunk(layersPayload, 10);

  const rendered = flatten(
    await Promise.all(
      layersChunks.map((chunk) => limit(() => generatedImageFromServer(chunk)))
    )
  );

  let partStartIndex = 0;
  const partMapped = [];
  const renderedParts = [];
  parts.map((part, index) => {
    const partEndIndex = partStartIndex + part.layers.length - 1;
    const renderedPartition = rendered.slice(partStartIndex, partEndIndex);
    partStartIndex = partEndIndex + 1;
    renderedParts.push(renderedPartition);
    const mapped = mapLayersToLayerSetting(
      part.layers,
      renderedPartition,
      part.store
    );
    partMapped.push(mapped);
  });
  return {
    mapped: partMapped,
    result: renderedParts
  };
};

export const getRenderedSceneLayer = async (
  store: StoreType
): Promise<ISceneLayer> => {
  const layers = await getSceneLayerForPreview(store?.toJSON()) || {
    desktop: undefined,
    mobile: undefined
  };

  if (!layers.desktop || !layers.desktop.value) return;
  try {
    const renderedDesktopPromise = await renderLayers(layers.desktop.value, store);
    const renderedMobilePromise =
      layers.mobile && layers.mobile.value
        ? await renderLayers(layers.mobile.value, store)
        : Promise.resolve();
    const [renderedDesktop, renderedMobile] = await Promise.all([
      renderedDesktopPromise,
      renderedMobilePromise
    ]);
    let renderedLayers: ISceneLayer = {
      desktop: renderedDesktop.mapped
    };
    if (renderedMobile) {
      renderedLayers = {
        ...renderedLayers,
        mobile: renderedMobile.mapped
      };
    }
    return renderedLayers;
  } catch (e) {
    return e;
  }
};

export const getPolotnoJson = () => {
  const store = getPolotnoStore();
  const polotnoJson = store?.toJSON() || undefined;
  return {
    obj: polotnoJson,
    str: polotnoJson ? JSON.stringify(polotnoJson) : undefined
  };
};

export const checkAndFixPolotnoJsonString = (jsonString: string) => {
  if (!jsonString) return null;

  try {
    const polotnoJson = JSON.parse(jsonString);

    const fixedJson = checkAndFixPolotnoJson(polotnoJson);

    return JSON.stringify(fixedJson);
  } catch {
    return null;
  }
};

export const checkAndFixPolotnoJson = (json?: IPolotnoJSON) => {
  if (!json) return null;
  const data = { ...json, fonts: undefined };
  const documentIsCorrect =
    data.width === POLOTNO_PAGE_WIDTH && data.height === POLOTNO_PAGE_HEIGHT;

  const pageIsCorrect = !data.pages.some((p) => {
    if (p.id.includes('-portrait')) {
      return p.width === POLOTNO_PAGE_HEIGHT && p.height === POLOTNO_PAGE_WIDTH;
    }
    return p.width === POLOTNO_PAGE_WIDTH && p.height === POLOTNO_PAGE_HEIGHT;
  });

  if (documentIsCorrect && pageIsCorrect) {
    return data;
  }

  return {
    ...data,
    width: POLOTNO_PAGE_WIDTH,
    height: POLOTNO_PAGE_HEIGHT,
    pages: data.pages.map((p) => {
      if (p.id.includes('-portrait')) {
        return {
          ...p,
          width: POLOTNO_PAGE_HEIGHT,
          height: POLOTNO_PAGE_WIDTH
        };
      }
      return {
        ...p,
        width: POLOTNO_PAGE_WIDTH,
        height: POLOTNO_PAGE_HEIGHT
      };
    })
  };
};
