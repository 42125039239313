import { orderBy } from 'lodash';
import {
  ISceneLayer,
  IStorybook,
  IStorybookPage,
  IStorybookSettings,
  IStorybookStatus,
  IStorybookVersion,
  StorybookVersionInfo
} from '../../interfaces';
import {
  blankFeaturedImageJson,
  blankLanguageFeaturedImageJson,
  checkForMissingPolotnoPage,
  getRenderedSceneLayer,
  IPolotnoJSON,
  TLanguage
} from '../../mappers/polotno';
import {
  addTitleAndSubtitleIntoSettingString,
  appendLayerIntoSetting,
  mapStorybookSettingsObjectToString,
  mapStorybookSettingsStringToObject
} from '../../mappers/storybook';
import { isVideo } from '../../utils/file';
import { downloadAndResizeImage } from '../../utils/image';
import { getPolotnoStore } from '../../utils/window';
import { mapLanguageToImageName } from '../clientSideServices/library';
import { uploadToS3 } from '../clientSideServices/session';
import { isProd } from '../../config';

export const getSBPageSetting = (
  pageSettings: string,
  defaultSettings: string
): IStorybookSettings => {
  const pageS = mapStorybookSettingsStringToObject(pageSettings);
  const defaultS = mapStorybookSettingsStringToObject(defaultSettings);

  return {
    ...pageS,
    color: pageS.color || defaultS.color,
    text: pageS.text || defaultS.text,
    background: pageS.background || defaultS.background,
    duration: pageS.duration ?? defaultS.duration,
    autoplay: pageS.autoplay ?? defaultS.autoplay,
    videoLoop: pageS.videoLoop ?? defaultS.videoLoop,
    muteParticipant: pageS.muteParticipant ?? defaultS.muteParticipant,
    layer: pageS.layer
  };
};

export const getSBPageSettingString = (
  pageSettings: string,
  globalSettings: string,
  appendPayload?: { [key: string]: string }
) =>
  mapStorybookSettingsObjectToString(
    appendPayload
      ? { ...appendPayload, ...getSBPageSetting(pageSettings, globalSettings) }
      : getSBPageSetting(pageSettings, globalSettings)
  );

export const getStatusLabel = (status: IStorybookStatus) =>
  status === 'published'
    ? 'Published'
    : status === 'activated'
    ? 'Active'
    : 'Draft';

export const getSBVersionForDropdown = (
  versions: IStorybookVersion[] | undefined
) => {
  if (!versions || !versions.length) return [];

  const ordered = orderBy(versions, ['version'], ['desc']) || [];
  const latestVersionStatus = ordered[0].status;
  const hasManyVersions = ordered.length > 1;

  const mapped = (ordered || [])?.map((v) => ({
    value: v?.version,
    label: `${v?.version}`
  }));

  mapped[0].label = `${ordered[0].version} - ${getStatusLabel(
    latestVersionStatus
  )}`;

  if (hasManyVersions && latestVersionStatus === 'draft') {
    mapped[1].label = `${ordered[1].version} - ${getStatusLabel(
      ordered[1].status
    )}`;
  }

  return mapped;
};

export const getSBVersionInfo = (
  versions: IStorybookVersion[] | undefined
): StorybookVersionInfo => {
  if (!versions || !versions.length)
    return {
      latestVersion: 0,
      latestActivatedVersion: 0,
      publishedVersion: 0,
      draftVersion: 0
    };

  const ordered = orderBy(versions, ['version'], ['desc']) || [];
  const hasDraft = ordered.some((v) => v.status === 'draft');

  return {
    latestVersion: ordered[0]?.version || 0,
    draftVersion: hasDraft ? ordered[0]?.version : 0,
    latestActivatedVersion:
      ordered.find((v) => v.status === 'activated')?.version || 0,
    publishedVersion:
      ordered.find((v) => v.status === 'published')?.version || 0
  };
};

export const getSBStats = (storybook: IStorybook) => {
  const versionInfo = getSBVersionInfo(storybook.availableVersions);

  const isPublished =
    storybook.status === 'published' ||
    (storybook.status === 'draft' &&
      versionInfo.latestVersion > 1 &&
      versionInfo.latestVersion - 1 === versionInfo.publishedVersion);
  const isActive =
    storybook.status === 'activated' ||
    (storybook.status === 'draft' &&
      versionInfo.latestVersion > 1 &&
      versionInfo.latestVersion - 1 === versionInfo.latestActivatedVersion);

  const canPublish = isActive || isPublished;

  return {
    versionInfo,
    isPublished,
    canPublish
  };
};

export const isStorybookHasDraft = (storybook: IStorybook) =>
  storybook.stats &&
  storybook.stats.versionInfo?.latestVersion ===
    storybook.stats.versionInfo?.draftVersion;

export enum StorybookAccessType {
  PASSCODE_REQUIRED = 'PASSCODE_REQUIRED',
  PASSCODE_WRONG = 'PASSCODE_WRONG',
  APPROVED = 'APPROVED',
  FORBIDDEN = 'FORBIDDEN'
}

export const getAccessTypeFromStorybookStatus = (status: IStorybookStatus) => {
  const strStatus = String(status);
  if (strStatus === '401') {
    return StorybookAccessType.PASSCODE_REQUIRED;
  }
  if (strStatus === '403') {
    return StorybookAccessType.PASSCODE_WRONG;
  }
  if (strStatus === 'published') {
    return StorybookAccessType.APPROVED;
  }
  return StorybookAccessType.FORBIDDEN;
};

export const sortStorybooks = (storybooks?: IStorybook[]): IStorybook[] =>
  orderBy(storybooks || [], ['modifiedAt', 'createdAt'], ['desc']);

export const getPolotnoJsonInStorybookPage = (
  page: IStorybookPage
): IPolotnoJSON => {
  if (page?.overlay) return JSON.parse(page.overlay);
  if (page?.editor) return page?.editor;
};

export const getFeaturedImageJson = (sb: IStorybook): IPolotnoJSON => {
  const featureStr = mapStorybookSettingsStringToObject(
    sb.settings
  ).featuredImage;
  if (featureStr) {
    try {
      let editor = JSON.parse(featureStr);
      if (editor.pages) {
        // if already editor json
        return editor;
      } else {
        // if feature is list language and url
        editor = getLanguageJSON(featureStr);
        return blankLanguageFeaturedImageJson(editor);
      }
    } catch (e) {
      // if feature is only 1 url
      const urlJson = { en: featureStr };
      return blankLanguageFeaturedImageJson(urlJson);
    }
  }
  // if feature is empty
  return blankFeaturedImageJson();
};

export const getLanguageJSON = (json: string): object => {
  if (!json) return { en: null };
  try {
    return JSON.parse(json);
  } catch (e) {
    return {
      en: json
    };
  }
};

export const getLanguaImageFromJSON = (
  imageStr: string,
  language: TLanguage
): string => {
  const imageObj = getLanguageJSON(imageStr);
  const featuredImageLanguage = language || 'en';
  return imageObj[featuredImageLanguage] || imageObj['en'] || '';
};

export const getStorybookPageType = (
  page: IStorybookPage,
  setting: IStorybookSettings
): 'image' | 'video' | 'layer' => {
  if (isVideo(page.url)) return 'video';

  if (setting.layer) {
    return 'layer';
  }
  if (setting.type) return setting.type;
  if (!page.url) return;
  return 'image';
};

export const skipGetStorybookEditor = (storybook: IStorybook) => {
  const pageContainsLayer = storybook?.pages?.some(
    (p) =>
      getStorybookPageType(
        p,
        getSBPageSetting(p.settings, storybook.settings)
      ) === 'layer'
  );
  const pageContainsOverlay = storybook?.pages?.some((p) => p.overlay);
  return pageContainsLayer && pageContainsOverlay;
};

export const hasVideoLayer = (
  settings: string | IStorybookSettings,
  device: keyof ISceneLayer
) => {
  const setting =
    typeof settings === 'string'
      ? mapStorybookSettingsStringToObject(settings)
      : settings;

  if (!setting) return false;
  if (device === 'desktop') {
    return setting.layer?.desktop?.some((l) => l.type === 'video');
  }
  if (device === 'mobile') {
    return setting.layer?.mobile?.some((l) => l.type === 'video');
  }

  return [
    ...(setting.layer?.desktop || []),
    ...(setting.layer?.mobile || [])
  ].some((l) => l.type === 'video');
};

export const getContentUrl = (
  page: IStorybookPage,
  settings: IStorybookSettings | string,
  language?: TLanguage
): {
  url: string;
  portraitUrl: string;
  thumbnail: string;
  portraitThumbnail: string;
  background: string;
} => {
  const activeLanguage = language || 'en';
  const mappedSettings =
    typeof settings === 'string'
      ? mapStorybookSettingsStringToObject(settings)
      : settings;

  const mainUrl = page.url || '';

  const thumbnails = getLanguageJSON(page.thumbnail || mainUrl);
  const urls = getLanguageJSON(mainUrl);
  const thumbnail = thumbnails[activeLanguage] || thumbnails['en'];
  const url = urls[activeLanguage] || urls['en'];

  return {
    url: url,
    portraitUrl:
      mappedSettings[mapLanguageToImageName('portraitUrl', activeLanguage)] ||
      mainUrl,
    thumbnail: thumbnail,
    portraitThumbnail:
      mappedSettings[
        mapLanguageToImageName('portraitThumbnail', activeLanguage)
      ] ||
      thumbnail ||
      mainUrl,
    background: mappedSettings.background
  };
};

export const cleanUpFontsFromPayload = (storybook: any) => {
  const { pages } = storybook;

  const newPages = [];

  pages.forEach((page) => {
    if (!page.overlay) {
      newPages.push(page);
    } else {
      const overlay = JSON.parse(page.overlay);
      const newOverlay = { ...overlay, fonts: [] };
      newPages.push({ ...page, overlay: JSON.stringify(newOverlay) });
    }
  });

  return { ...storybook, pages: newPages };
};

export const generateStorybookPagePreview = ({
  page,
  onSuccess,
  onLoading,
  onFailed
}: {
  page: IStorybookPage;
  onSuccess: (page: IStorybookPage) => void;
  onLoading: (loading: boolean) => void;
  onFailed: (message: string) => void;
}) => {
  onLoading(true);

  const store = getPolotnoStore();

  const missingPage = checkForMissingPolotnoPage(store?.toJSON());

  const hasLandscapePage = !(
    missingPage === 'desktop' || missingPage === 'compare'
  );
  if (!hasLandscapePage) {
    onLoading(false);
    onFailed('Landscape page cannot be blank. Please add some content to it.');
    return;
  }
  getRenderedSceneLayer(store)
    .then((layer) => {
      onLoading(false);
      const previewPage = {
        ...page,
        settings: appendLayerIntoSetting(page.settings, layer)
      };
      onSuccess(previewPage);
    })
    .catch(() => {
      onLoading(false);
      onFailed('Error while generating preview, please try again later.');
    });
};

export const autoGeneratedPosterName = 'poster.jpeg';

export const hasDesignedFeaturedImage = (storybook: IStorybook): boolean =>
  !!storybook?.featuredImage && storybook.featuredImage.includes('manual');

export const setImageAsFeaturedImage = async (imageUrl: string, id: string) => {
  const filePath = 'content/converted';
  const bucket = 'storiez-campaignpics';
  const key = `${filePath}/${id}/featuredImages/${id}_${autoGeneratedPosterName}`;
  try {
    const image = await downloadAndResizeImage(imageUrl, 1200, 627);
    await uploadToS3(image, bucket, key, undefined, true, 'image/jpeg');
    return key;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getInitStorybookPageForDocumentViewer = (
  storybook: IStorybook
) => {
  const firstPage = storybook.pages?.[0];
  return {
    id: storybook.id,
    file: storybook.title,
    url: firstPage?.url,
    setting: addTitleAndSubtitleIntoSettingString({
      title: firstPage?.title,
      subtitle: firstPage?.subtitle,
      settings: firstPage?.settings
    })
  };
};

export const isValidBrandForStorybook = (
  storybook: IStorybook,
  brandId: string,
  parentBrandId: string,
  whitelistDomain: string[]
) => {
  const host = () => {
    if (typeof window !== 'undefined') {
      return window.location.host;
    }
    return '';
  };
  const validBrand = storybook?.brand === brandId;
  const validParentBrand = storybook?.brand === parentBrandId;
  const isValidHost = whitelistDomain?.includes(host());
  return !isProd || validBrand || validParentBrand || isValidHost;
};
