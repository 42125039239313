import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { apigClient } from '../advisorHub/clientSideServices/aws';
import { logEvent } from '../analytics';
import { IAccount, IMeetUser, IUser, IUserEntitlement } from '../interfaces';
import { ASSOCIATED_STORE_ID, DID_SHOW_ERROR, IDENTITY_ID } from '../utils/constants';
import { tryGetParentWindowField } from '../utils/window';
import httpClient from './http';

export const getUserByIdentityId = (
  identityId: string
): Promise<AxiosResponse<IUser>> => httpClient.get(`/api/users/${identityId}`);
export const getUserEntitlements = (): Promise<
  AxiosResponse<IUserEntitlement[]>
> => httpClient.get(`/api/users/userEntitlements`);

export const saveUser = (user: IUser) =>
  httpClient.post('/api/users', { user });

export const getAccountBySpaceId = (id: string) =>
  httpClient.get(`/api/account/${id}`);

export const saveAccount = (account: IAccount) =>
  httpClient.post('/api/account', {
    account: { ...account, accountId: account.id, name: account.spaceName }
  });

export const getMeetUserByIdentityId = async (
  userId: string
): Promise<IMeetUser> => {
  try {
    const client = await apigClient();
    const path = `/entitlements/v1/byUserId`;
    const body = {
      userId: userId
    };
    const usersResponse = await client.invokeApi({}, path, 'POST', {}, body);
    return usersResponse?.data[0]?.roles[0];
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const createUser = (
  identityId: string,
  name: string,
  email: string | undefined = undefined,
  countryCode: string | undefined = undefined,
  phoneNumber: string | undefined = undefined
): IUser => ({
  id: identityId,
  alias: name,
  first_name: name.split(' ')[0],
  full_name: name || '',
  email: email || '',
  mobilePhoneCountryCode: countryCode || '',
  mobileNumber: phoneNumber || '',
  enabled: 0,
  last_name: '',
  user_name: name || '',
  userType: 'web'
});

export const storeUserAssociatedStoreId = (associatedStoreId: string) => {
  window[ASSOCIATED_STORE_ID] = associatedStoreId;
  try {
    localStorage.setItem(ASSOCIATED_STORE_ID, associatedStoreId);
  } catch (error) {
    console.error(`Caching associated store ID in local storage failed`, error);
  }
};

export const getUserAssociatedStoreId = () => {
  if (window[ASSOCIATED_STORE_ID]) {
    return window[ASSOCIATED_STORE_ID];
  }
  if (tryGetParentWindowField(ASSOCIATED_STORE_ID)) {
    return tryGetParentWindowField(ASSOCIATED_STORE_ID);
  }
  let id;
  try {
    id = localStorage.getItem(ASSOCIATED_STORE_ID);
  } catch (error) {
    console.error(`Get associated store ID in local storage failed`, error);
  }
  return id;
};

export const getCurrentUserEntitlements = async (): Promise<
  IUserEntitlement[]
> => {
  try {
    const client = await apigClient();
    //const identityId = (await Auth.currentCredentials()).identityId; fix XXXX
    const identityId = (await localStorage.getItem(IDENTITY_ID));
    const path = `/entitlements/v1/byUserId`;
    const body = {
      userId: identityId
    };
    const usersResponse = await client.invokeApi({}, path, 'POST', {}, body);
    return usersResponse?.data;
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};


export const getCurrentUserInfo = async () => {
  try {
    const userInfo = await Auth.currentUserInfo();
    return userInfo;
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserInfor = async (infor: object) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      ...infor
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserStatus = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:onboarding_status': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserAssetLibGuideTour = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:gt_asset_library': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserManagementGuideTour = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:gt_user_management': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateTeamGuideTour = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:gt_teams': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateBrandingSettingGuideTour = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:gt_branding': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserStoriezGuideTour = async (status: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:gt_storiez': status
    });
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};

export const updateUserRole = async ({
  userId,
  brandId,
  role
}: {
  userId: string;
  brandId: string;
  role;
}) => {
  try {
    const client = await apigClient();
    const body = {
      userId,
      brandId,
      role
    };
    const path = `/users/v4/web/assignRoleToUser`;
    const result = await client.invokeApi({}, path, 'POST', {}, body);
    return result;
  } catch (error) {
    logEvent(DID_SHOW_ERROR, DID_SHOW_ERROR, { error });
  }
};
