const amplify = {
  cognito: {
    REGION: 'ap-southeast-1',
    USER_POOL_ID: 'ap-southeast-1_VYd7ElqAI',
    APP_CLIENT_ID: '2it7sf1vko4qb7q49l66rbln31',
    IDENTITY_POOL_ID: 'ap-southeast-1:bac70d97-91b7-4970-94a9-fac5432c4896'
  }
};

export const STREAM_TOKENS_ATTR = 'custom:live_stream_tokens';
export default amplify;
