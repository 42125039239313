import _, { isPlainObject } from 'lodash';
import ReactGA from 'react-ga';
import httpClient, { getRequestSignature } from '../clientSideServices/http';
import { getUserAssociatedStoreId } from '../clientSideServices/users';
import { isProd, isStaging } from '../config';
import { i18n } from '../i18n';
import {
  AnalyticEvent,
  EyeballLoggerPayload,
  EyeballTimeView,
  IStore,
  Source
} from '../interfaces';
import { sessionKey } from '../utils/analyticsSession';
import { getNow } from '../utils/clock';
import {
  DID_START_SESSION,
  DID_VIEW_PAGE,
  EYEBALL_TIME,
  GA_INITIALIZED
} from '../utils/constants';
import { getIdentityId } from '../utils/identity';
import { generateV4UUID } from '../utils/identityGenerator';
import { PageView } from '../utils/pageView';
import {
  getUrlWithParentWindowQueryParams,
  tryGetParentWindowField
} from '../utils/window';
import {
  CUSTOM_ANALYTICS_EVENT,
  DID_CLICK_BUTTON,
  DID_KEY_PRESS,
  DID_VIEW_CONTENT,
  JITSI_MEET_EVENT
} from './../utils/constants';
import { isOnClientSide } from './../utils/window';

let events: AnalyticEvent[] = [];

export const getAELeft = (): AnalyticEvent[] => {
  let eventsLeft;
  try {
    eventsLeft = JSON.parse(localStorage.getItem(CUSTOM_ANALYTICS_EVENT));
  } catch (e) {
    return [];
  }
  return eventsLeft;
};

const noDelayEvents = [EYEBALL_TIME, DID_VIEW_PAGE, DID_VIEW_CONTENT];

const emptyAELeft = (): void => {
  try {
    localStorage.removeItem(CUSTOM_ANALYTICS_EVENT);
  } catch (e) {
    console.error(e);
  }
};

export const logCustomAnalytics = (events: AnalyticEvent[]) => {
  //create diffrent timestamps for each event
  const _events = events.map((ev, index) => ({
    ...ev,
    event_timestamp: ev.event_timestamp + index
  }));
  httpClient.post('/api/analytics', _events).catch((e) => {
    logException(
      `logCustomAnalytics failed with exception: ${JSON.stringify(e)}`
    );
  });
};

export const flush = () => {
  if (events.length) {
    logCustomAnalytics([...events]);
  }
  events = [];
};

export const addEventToQueue = (event: AnalyticEvent, flushSoon?: boolean) => {
  events = [...events, event];
  if (events.length >= 5 || flushSoon) {
    flush();
  }
};

if (isOnClientSide()) {
  // Existing beforeunload listener
  window.addEventListener('beforeunload', function () {
    localStorage.setItem(CUSTOM_ANALYTICS_EVENT, JSON.stringify(events));
  });

  // Add visibility change handler
  document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden') {
      flush();
    }
  });

  // Add blur handler for tab switching
  window.addEventListener('blur', function() {
    flush();
  });

  // Existing load listener
  window.addEventListener('load', function () {
    const eventsLeft = getAELeft();
    if (eventsLeft?.length) {
      logCustomAnalytics(eventsLeft);
    }
    emptyAELeft();

    setInterval(() => {
      flush();
    }, 10000);
  });
}

const inspifyGa = 'UA-162755127-1';

export const initGA = (ga = inspifyGa) => {
  if (isProd && !window[GA_INITIALIZED]) {
    ReactGA.initialize(ga);
    window[GA_INITIALIZED] = true;
  }
};

const logOnlyGAInitialized = (f: () => void) => {
  if (window[GA_INITIALIZED]) {
    f();
  }
};

const logPageViewGA = (pathname: string) => {
  logOnlyGAInitialized(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  });
};

const logEventGA = (category: string, action: string) => {
  logOnlyGAInitialized(() => {
    ReactGA.event({ category, action });
  });
};

const logExceptionGA = (description: string, fatal: boolean) => {
  logOnlyGAInitialized(() => {
    ReactGA.exception({ description, fatal });
  });
};

export const logPageView = (
  storeId: string | undefined = undefined,
  pageView: PageView
) => {
  
  logPageViewGA(window.location.pathname);
  logCustomEvent(
    DID_VIEW_PAGE,
    { url: window.location.href, pageType: pageView },
    storeId
  );
};

export const logSessionStart = (sessionId: string, storeId?: string) => {
  console.log(`session start ${sessionId}`);
  logCustomEvent(DID_START_SESSION, { sessionId }, storeId);
};

export const getStoreIdFromUrl = () => {
  const regex = /store=([a-zA-Z0-9-]+)/;
  const match = window.location.search.match(regex);
  if (match && match.length === 2) {
    return match[1];
  }
  return null;
};

export const isHubPage = (pathname: string, search: string) => {
  const path = pathname?.replace('#', '').split('/');
  return (
    path?.[1] === 'hub' ||
    path?.[1] === 'nudge' ||
    search.indexOf(`source=${Source.Nudge}`) > -1 ||
    search.indexOf(`source=${Source.AdvisorHub}`) > -1
  );
};

const getEnv = () => {
  if (isProd) return 'prod';
  if (isStaging) return 'staging';
  return 'dev';
};

export const getAnalyticEvent = (
  category: string,
  payload: any,
  storeId: string | undefined = undefined
) => {
  const store = window['store'] as IStore | undefined;
  const associatedStoreId = getUserAssociatedStoreId();
  const url = payload?.url || getUrlWithParentWindowQueryParams();
  const uuid = payload?.uuid || window['uuid'];
  const organisationId =
    payload?.organisationId || window['parentId'] || window['brandId'];
  const _storeId =
    storeId || getStoreIdFromUrl() || store?.id || window['storeId'];
  const additionalInfo = _.omitBy(
    {
      uuid,
      organisationId,
      storeId: _storeId
    },
    _.isUndefined
  );
  const event_timestamp = payload?.event_timestamp || getNow().getTime();
  const country = payload?.country || window['country'] || undefined;

  if (payload?.event_timestamp) {
    delete payload.event_timestamp;
  }

  const event: AnalyticEvent = {
    ...additionalInfo,
    type: category,
    payload: {
      ...payload,
      env: getEnv(),
      originalUrl: window.location.href,
      url,
      userAgent: navigator.userAgent,
      storeId: _storeId,
      language: (payload || {}).language || i18n?.language || 'en',
      sessionId:
        (payload || {}).sessionId ||
        window[sessionKey] ||
        tryGetParentWindowField(sessionKey),
      associatedStoreId: associatedStoreId || undefined,
      country: country,
      city: window['city'] || undefined
    },
    event_timestamp,
    identityId: getIdentityId(),
    sourceSystem: isHubPage(window.location.pathname, window.location.search)
      ? 'SALES_HUB'
      : 'TOUCH_WEB'
  };
  return event;
};

const logCustomEvent = (
  category: string,
  payload: any,
  storeId: string | undefined = undefined,
  noDelay?: boolean
) => {
  const event = getAnalyticEvent(category, payload, storeId);
  const eventType = event.type;
  const shouldDelay = noDelay || noDelayEvents.includes(eventType);
  if (getIdentityId()) {
    addEventToQueue(event, shouldDelay);
  } else {
    logException('identityId is null in logEvent call!');
    addEventToQueue({ ...event, identityId: generateV4UUID() }, shouldDelay);
  }
};

export type LogEvent = (
  category?: string,
  action?: string,
  payload?: any,
  noDelay?: boolean
) => void;

export const logEvent: LogEvent = (
  category = '',
  action = '',
  payload = null,
  noDelay?: boolean
) => {
  if (category) {
    logEventGA(category, action);
    const defaultPayload = {
      action: action || category
    };
    if (payload && isPlainObject(payload)) {
      logCustomEvent(
        category,
        {
          ...defaultPayload,
          ...payload
        },
        undefined,
        noDelay
      );
    } else {
      logCustomEvent(category, defaultPayload, undefined, noDelay);
    }
  }
};

export const logEyeballTime = (
  durationInMs: number,
  payload: EyeballLoggerPayload<EyeballTimeView>
) => {
  logCustomEvent(EYEBALL_TIME, {
    ...payload,
    view: undefined,
    durationInMs,
    type: payload.view,
    id: payload.id
  });
};

export const logEyeballTimeOnExit = (durationInMs: number, payload) => {
  const eventPayload = {
    ...payload,
    view: undefined,
    durationInMs,
    type: payload.view,
    id: payload.id
  };
  const event = getAnalyticEvent(EYEBALL_TIME, eventPayload);
  const data = JSON.stringify([event]);
  const url = '/api/analytics';
  const signature = getRequestSignature({
    data: [event],
    method: 'POST',
    url
  });
  return fetch('/api/analytics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-ins-date': signature.date,
      'x-ins-signature': signature.key
    },
    body: data,
    keepalive: true
  });
};

export const logKeyPress = (keyCode: string) => {
  logEvent(DID_KEY_PRESS, DID_KEY_PRESS, { keyCode });
};

export const logClickButton = (button: string) => {
  logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button });
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    logExceptionGA(description, fatal);
  }
};

export const logJitsiEvent = (action: string, additionalInfor: object = {}) => {
  console.log(
    `%cJitsiEvent ${action} triggered`,
    'color: #FF4500',
    additionalInfor || {}
  );
  logEvent(JITSI_MEET_EVENT, action, { ...(additionalInfor || {}) });
};
