import { AnyAction } from 'redux';
import { ChatMessageType } from '../components/ChatConnectionContext';
import { JitsiConnectionStatus } from '../components/Meeting/CockpitContainer';
import { MeetingCommand } from '../components/Meeting/commands';
import { PageView } from '../utils/pageView';
import { IChopardState } from './chopard';
import { ILoubiAirwaysState } from './loubiairways';
import { ILoubiFutureState } from './loubifuture';
import { IVCAState } from './vca';
import { ICommon3DState } from './common3D';
import { IMeetingChat } from '../components/Meeting/Chat/chatUtils';
import { IPSState } from '../components/PixelStream/PSInterface';
import { IPolotnoJSON, TLanguage } from '../mappers/polotno';
import { ICollaboratorContext } from '../advisorHub/clientSideServices/collaboration';

export interface IMainState {
  clientState: ClientState;
  serverState: ServerState;
}

export interface ServerState {
  productInspirations?: {
    product?: IProduct;
    inspirations: IInspiration[];
  };
}

export interface JitsiEndpointMessage {
  type?: string;
  value?: MeetingCommand;
  attributes?: any;
}

export interface JitsiTrack {
  getId: () => string;
  getParticipantId: () => string;
  isMuted: () => boolean;
  attach: (container: any) => void;
  detach: (container: any) => void;
  isActive: () => boolean;
  getType: () => 'video' | 'audio';
  getOriginalStream: () => any;
  isVideoTrack?: () => boolean;
  isAudioTrack?: () => boolean;
  videoType?: 'camera' | 'desktop';
  stream?: MediaStream;
  track?: MediaStreamTrack;
}

export interface JitsiLocalTrack extends JitsiTrack {
  mute: () => Promise<void>;
  unmute: () => Promise<void>;
  dispose: () => Promise<void>;
  addEventListener: (event: string, handler: Function) => void;
  getDeviceId: () => string;
  on?: (event: string, callback: Function) => void;
  off?: (event: string, callback: Function) => void;
  //apply effect by swapping out the existing MediaStream on jisti track
  //pass undefined to remove the effect and restore the o.g MediaStream
  setEffect?: (effect: any | undefined) => any;
}

export interface JitsiDevice {
  deviceId?: string;
  groupId?: string;
  kind?: string;
  label?: string;
}
export enum RecordingEntitlement {
  PRIVATE = 'PRIVATE',
  SITE = 'SITE',
  BRAND = 'BRAND'
}

export enum RoleEntitlement {
  User = 'User',
  Admin = 'Admin',
  Designer = 'Designer',
  Marketing = 'Marketing',
  Owner = 'owner'
}

export enum DevicePermissionStatus {
  GRANTED = 'GRANTED',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING'
}

export type JitsiRemoteTrack = JitsiTrack;

export interface JitsiParticipant {
  getConnectionStatus?: () => string;
  _role?: string;
}

export interface JitsiMeetingRoom {
  myUserId: () => string | undefined;
  sendEndpointMessage: (
    recipient: string,
    message: JitsiEndpointMessage
  ) => void;
  selectParticipants: (participantIds: string[]) => void;
  leave: () => Promise<void>;
  setDisplayName: (displayName: string) => void;
  addTrack: (track: JitsiLocalTrack) => Promise<void>;
  on: (event: string, handler: Function) => void;
  off: (event: string, handler: Function) => void;
  getLocalTracks: () => JitsiLocalTrack[];
  setSenderVideoConstraint: (quality: number) => Promise<void>;
  muteParticipant?: (participantId: string) => void;
  kickParticipant?: (participantId: string) => void;
  isModerator?: () => boolean;
  grantOwner?: (participantId: string) => void;
  replaceTrack?: (oldTrack: JitsiTrack, newTrack: JitsiTrack) => Promise<void>;
  setLocalParticipantProperty?: (field: string, value: any) => void;
  getLocalParticipantProperty?: (field: string) => any;
  setReceiverConstraints?: (constraints: any) => void;
}
export type JitsiConnection = any;

export enum MeetingRole {
  ADVISOR = 'ADVISOR',
  CO_ADVISOR = 'CO_ADVISOR',
  CLIENT = 'CLIENT',
  MC = 'MC',
  STUDIO = 'STUDIO'
}
export interface ActiveDevices {
  microphone?: string;
  speaker?: string;
  camera?: string;
}

export enum ParticipantMeetingState {
  IN_THE_LOUNGE = 'IN_THE_LOUNGE',
  SHOW_WELCOME_VIDEO = 'SHOW_WELCOME_VIDEO',
  ENTERED_FROM_LOUNGE = 'ENTERED_FROM_LOUNGE',
  LEFT_MEETING = 'LEFT_MEETING'
}

export const participantInMeetingStates = [
  ParticipantMeetingState.ENTERED_FROM_LOUNGE,
  ParticipantMeetingState.SHOW_WELCOME_VIDEO
];

export enum MeetingLayoutMode {
  NORMAL = 'NORMAL',
  TILE = 'TILE',
  PRESENTATION = 'PRESENTATION',
  ONE_THIRD = 'ONE_THIRD'
}

export interface OneThirdLayout {
  presenterId: string;
  enlargedId: string;
}
export interface MeetingLayout {
  mode?: MeetingLayoutMode;
  oneThirdLayout?: OneThirdLayout;
  presenterIds?: string[];
  studioViewState?: IStudioViewState;
  enlargedVideoParticipantId?: string;
  invisibleParticipantIds?: string[];
  pinnedParticipantIds?: string[];
  hideControls?: boolean;
  playerStatusParticipantIds?: string[];
}

export enum NotificationType {
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS'
}

export enum MeetingNotification {
  LEAVE_MEETING = 'LEAVE_MEETING',
  MUTED_AUDIO = 'MUTED_AUDIO',
  MUTED_VIDEO = 'MUTED_VIDEO',
  AUDIO_DISCONNECTED = 'AUDIO_DISCONNECTED',
  VIDEO_DISCONNECTED = 'VIDEO_DISCONNECTED'
}

export interface IMeetingRemoteUsers {
  [key: string]: {
    identityId?: string;
    role?: MeetingRole;
    wishlistProducts?: IProduct[];
    interestedProducts?: IProduct[];
    audioLevel?: number;
    audioMuted?: boolean;
    videoMuted?: boolean;
    ready?: boolean;
    displayName?: string;
    tracks?: JitsiRemoteTrack[];
    connectionStatus?: JitsiConnectionStatus;
    jitsiUser?: JitsiParticipant;
    shouldShowVideo?: boolean;
    stats?: IMeetingStats;
    hasDisconnected?: boolean;
  };
}

export interface IMeetingLocalUser {
  participantId?: string;
  role?: MeetingRole;
  audioLevel?: number;
  audioMuted?: boolean;
  lastAudioMuted?: boolean;
  videoMuted?: boolean;
  tracks?: JitsiLocalTrack[];
  availableDevices?: JitsiDevice[];
  activeDevices?: ActiveDevices;
  isInputChangeAvailable?: boolean;
  isOutputChangeAvailable?: boolean;
  kickedOut?: boolean;
  videoEffect?: {
    showDialog?: boolean;
    effect?: JitsiVideoTrackEffect | undefined;
  };
  devicePermission?: {
    audio?: DevicePermissionStatus;
    video?: DevicePermissionStatus;
  };
  isSharingScreen?: boolean;
  isOnPresenterMode?: boolean;
}

export interface IReconnectState {
  layout?: MeetingLayout;
  displayName?: string;
  ts?: number;
  id?: string;
  meetingState?: ParticipantMeetingState;
  videoMuted: boolean;
  audioMuted: boolean;
  vBackground: JitsiVideoTrackEffect;
  participantId: string;
}
export interface IPixelStreamServer {
  host: string;
  protocol: string;
  storeId: string;
}

export enum WarningType {
  ERROR = 'ERROR',
  PERMISSION_DENIED = 'PERMISSION_DENIED'
}
export interface IDeviceWarning {
  description: string | null;
  descriptionKey: string;
  titleKey: string;
  id: string;
}
export interface IMeeting {
  meetingId?: string;
  state?: ParticipantMeetingState;
  localUser?: IMeetingLocalUser;
  remoteUsers?: IMeetingRemoteUsers;
  initialParticipantsInLounge?: string[];
  layout?: MeetingLayout;
  layoutToRestore?: MeetingLayout;
  showLayoutControlButton?: boolean;
  remoteSceneId?: string;
  remoteView?: PanoView;
  remoteShowHotSpotName?: string;
  remoteHideHotSpotName?: string;
  remoteProductImageSliderIndex?: number;
  isPresenter?: boolean;
  bridgeChannelOpened?: boolean;
  remotePopupAction?: AnyAction;
  clientDetailPanelParticipantId?: string;
  showClientDetailPanel?: boolean;
  advisorName?: string;
  advisorIdentityId?: string;
  advisorParticipantId?: string;
  dominantSpeakerParticipantId?: string;
  popupState?: IMeetingPopupState;
  deviceWarning?: IDeviceWarning;
  raiseHand?: {
    participantIds?: string[];
    participantInAlert?: string[];
    approved?: boolean;
  };
  stats?: IMeetingStats;
  associatedDevices?: IStoreDevice[];
  advisorEndsMeeting?: boolean;
  serverUrl?: string;
  notifications?: MeetingNotification[];
  participantControls?: string;
  autoAdmit?: boolean;
  date?: number;
  chat?: IMeetingChat[];
  languageCode?: string;
  statsMode?: boolean;
  joinAt?: number;
  reconnect?: {
    isReconnect?: boolean;
    meetingState?: string;
    isReconnected?: boolean;
    oldParticipantId?: string;
  };
  psServer?: IPixelStreamServer;
  isOverlayImgVisible?: boolean;
  jitsi?: JitsiMeetingRoom;
}

export interface IStoreDevice {
  deviceId?: string;
  deviceType?: 'SSP' | 'Lense' | 'Bolt';
  name?: string;
  brand?: string;
  storeId?: string;
  status?: 'AVAILABLE' | 'IN_USE' | 'ONLINE';
  location?: {
    region: string;
    country: string;
    city?: string;
  };
}

export interface IBandWidthStats {
  download: number | typeof NaN;
  upload: number | typeof NaN;
}
interface IPacketLoss extends IBandWidthStats {
  total: number;
}

interface IBitrate extends IBandWidthStats {
  audio?: IBandWidthStats;
  video?: IBandWidthStats;
}
export interface IMeetingStats {
  maxEnabledResolution?: number;
  resolution?: {
    [key: string]: {
      [key: string]: {
        height: number;
        width: number;
      };
    };
  };
  codec?: {
    [key: string]: {
      [key: string]: {
        audio: string;
        video: string;
      };
    };
  };
  framerate?: {
    [key: string]: {
      [key: string]: number;
    };
  };
  bitrate?: IBitrate;
  packetLoss?: IPacketLoss;
  bandwidth?: IBandWidthStats;
  bridgeCount?: number;
  connectionQuality?: number;
}

export interface IRemoteUserStats {
  bitrate?: IBitrate;
  connectionQuality?: number;
  packetLoss?: IPacketLoss;
  jvbRTT?: number;
  maxEnabledResolution?: number;
}
export interface IStudioViewState {
  zoomLevel?: number;
  rotateDeg?: number;
}

export interface IMeetingPopupState {
  url?: string;
  videoPlayWithSound?: boolean;
  action?: AnyAction;
  hideMeetingVeil?: boolean;
}

export interface IStorySearchState {
  keywords?: string;
  selectedLanguage?: string;
  selectedContext?: string[];
  showFilter?: boolean;
}

export interface IFile {
  id: string;
  title: string;
  pages: string[];
  thumbnails?: string[];
  pagesSetting?: string[];
  activePage?: number;
  hiddenPages?: number[];
  hideInMenu?: boolean;
  type?: 'document' | 'storybook';
  silences?: boolean[];
}

export interface IFilePageSettings extends IStorybookSettings {
  title?: string;
  subtitle?: string;
  pageId?: string;
}

export interface IPlayerControl {
  play?: boolean;
  seekTo?: number;
  currentScene?: number;
}

export enum FileType {
  IMAGE = 'IMAGE',
  GIF = 'GIF',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
  SVG = 'SVG',
  FONT = 'FONT'
}

export enum FileViewerLayout {
  FULLSCREEN = 'FILE_VIEWER_LAYOUT.FULLSCREEN',
  PRESENTATION = 'FILE_VIEWER_LAYOUT.PRESENTATION',
  SIDE = 'FILE_VIEWER_LAYOUT.SIDE',
  NORMAL = 'FILE_VIEWER_LAYOUT.NORMAL',
  MINIMIZE = 'FILE_VIEWER_LAYOUT.MINIMIZE'
}
export interface IStorybookFileState {
  files?: IFile[];
  activeFile?: string;
  currentPage?: {
    id: string;
    file: string;
    url: string;
    setting?: string;
    silence?: boolean;
  };
  controller?: string;
  prevUrl?: string;
  nextUrl?: string;
  layoutMode?: FileViewerLayout;
  playerControl?: IPlayerControl;
  sbFromMeeting?: IFile;
}

export interface FocusedItemState {
  // prettier-ignore
  section?: 'compareProduct' | 'inspirationSlider' | 'productsSlider' | 'searchProductFilter' | 'personalize';
  itemId?: string;
}

export interface ProductSearchState {
  keywords?: string;
  compareMode?: boolean;
  families?: ProductFamily[];
  filters?: CloudSearchFilter[];
  activeFilterOption?: string | number;
}

export enum AppFeature {
  MEET_CHAT = 'FEATURE.MEET_CHAT'
}

export interface ViewportInfo {
  width?: number;
  height?: number;
  onPhone?: boolean;
  onMobile?: boolean;
  type?: ViewportType;
}
export interface ClientState {
  pathHistory?: {
    pageView: PageView;
    url: string;
  }[];
  country?: string;
  store?: IStore;
  virtualBoutiqueConfig?: IVirtualBoutiqueConfig;
  inspirationsList?: IInspiration[];
  vb?: VirtualBoutiqueState;
  teleport?: ITeleportState;
  productsToCompare?: IComparedProduct[];
  focusedItem?: FocusedItemState;
  showBoutiqueJourney?: boolean;
  disableBoutiqueJourney?: boolean;
  wishlist?: IWishlistPayload;
  wishlistTab?: WishlistType;
  meeting?: IMeeting;
  user?: IUser;
  userLoadState?: LoadingStatus;
  inspirationRelatedProducts?: {
    loading?: boolean;
    data?: {
      [key: string]: IProduct[];
    };
  };
  productDetails?: IProductDetails;
  productSearch?: ProductSearchState;
  storybookViewerIndex?: number;
  storySearch?: IStorySearchState;
  similarProducts?: {
    loading?: boolean;
    data?: {
      [key: string]: IProduct[];
    };
  };
  shoppingCart?: IShoppingCart;
  hub?: IAdvisorHubState;
  productAccessories?: IProductAccessories;
  loubiAirways?: ILoubiAirwaysState;
  loubiFuture?: ILoubiFutureState;
  vca?: IVCAState;
  agreedToCookie?: boolean;
  fileViewer?: IStorybookFileState;
  chopard?: IChopardState;
  common3D?: ICommon3DState;
  product?: IProductState;
  tooltip?: string;
  activeFeatures?: AppFeature[];
  viewport?: ViewportInfo;
  isBrowseStorybookInMeeting?: boolean;
}

export interface IHubUser {
  readonly id: string;
  readonly name: string;
  readonly alias: string;
  readonly email?: string;
  readonly language?: string;
  readonly storeId: string;
  readonly associatedStoreId?: string;
  readonly location?: string;
  readonly brandLogoUrl?: string;
  readonly brandName?: string;
  readonly brandId?: string;
  readonly socialMedia?: ISocialMedia[];
  readonly entitlements?: any;
}

export interface IEntitlement {
  readonly entitlementType: string;
  uuid: string;
}

export interface IHubUserPayload {
  readonly id: string;
  readonly alias: string;
  readonly email?: string;
  readonly avatar_picture?: string;
  readonly language?: string;
  readonly entitlements: IEntitlement[];
}

export enum HubAlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO'
}
export interface IHubSearchFilters {
  productFamilies?: ProductFamily[];
  productSearchFilters?: ProductSearchFilter[];
  storyContext?: { value: string; label: string }[];
  storyLanguages?: { value: string; label: string }[];
}

export enum SessionStatus {
  SESSION_CREATED = 'SESSION_CREATED',
  SESSION_EDITED = 'SESSION_EDITED',
  SESSION_PREPARED = 'SESSION_PREPARED',
  FAILED_TO_PREPARE = 'FAILED_TO_PREPARE'
}

export type ISessionDetails = ISessionPayload;

export interface ISessionParticipant {
  name: string | null;
  role: string | null;
  email: string | null;
  noWaitingLounge: 0 | 1;
}

export interface ISessionFile {
  order: number;
  title: string;
  bucket: string;
  key: string;
}

export interface ISessionConvertedFile {
  title: string;
  order: number;
  convertedItems: {
    bucket: string;
    key: string;
  }[];
}

export interface ISessionPayload {
  id: string;
  storeId?: string;
  brand?: string;
  sessionType: 'live' | 'scheduled' | 'room' | 'facebook';
  topic: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  timezone: string;
  description: string;
  region?: string;
  eventCountdownPage?: 0 | 1;
  recordSession?: 0 | 1;
  hostMuteVideo?: 0 | 1;
  participantMuteVideo?: 0 | 1;
  joinAnytime?: 0 | 1;
  muteUponEntry?: 0 | 1;
  participants?: ISessionParticipant[];
  uploadedFiles?: ISessionFile[];
  preparedFiles?: ISessionConvertedFile[];
  uploadedStoryBook?: any[];
  preparedStoryBook?: any[];
  status?: string;
  createdBy?: string;
  createdAt?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  storybooks?: string[];
  ownerToken?: string;
  ownerId?: string;
}

export interface ISessionFormData {
  id?: string;
  storeId?: string;
  topic?: string;
  type?: 'live' | 'scheduled' | 'room';
  description?: string;
  recordSession?: boolean | 0 | 1;
  hostMuteVideo?: boolean | 0 | 1;
  participantMuteVideo?: boolean | 0 | 1;
  joinAnytime?: boolean | 0 | 1;
  muteUponEntry?: boolean | 0 | 1;
  eventCountdownPage?: boolean | 0 | 1;
  uploadedFiles?: ISessionFile[];
  preparedFiles?: ISessionConvertedFile[];
  uploadedStoryBook?: any[] | [];
  preparedStoryBook?: any[] | [];
  createdBy?: string;
  status?: string;
  region?: string;
  modifiedAt?: string;
  createdAt?: string;
  brand?: string;
  modifiedBy?: string;
  timezone?: string;
  startDate?: Date;
  endDate?: Date;
  startTime?: Date;
  endTime?: Date;
  participants?: ISessionParticipant[];
  storybooks?: string[] | [];
}

export interface IFileUploadStatus {
  name: string;
  progressPercentage: number;
  error?: Error;
}

export interface IHubSessionState {
  progressStatus?: ProgressStatus;
  date?: Date;
  sessionList?: ISessionDetails[];
  formData?: ISessionFormData;
  fileUploadStatus?: IFileUploadStatus[];
}

export interface IFileToUpload {
  file: File;
  name: string;
  key: string;
  bucket: string;
  metadata?: SBMetadata;
}

export interface IUserInfoState {
  id?: string;
  alias: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
}

export interface ISceneState {
  currentScene?: ILibraryScene;
  dataHasChanged?: boolean;
  pexelsUploadStatus?: IPexelsUploadStatus;
}

export interface AssetLibraryPopupState {
  closeButton?: {
    onClose: () => void;
    label?: string;
  };
  okButton?: {
    onOk: () => void;
    label?: string;
  };
  onClose?: () => void;
  onSelectAsset?: (asset: IAsset) => void;
  onAddFileToUpload?: (file: File) => void;
  popupTitle?: string;
  popupContext?: string;
  keywords?: string[];
  acceptTypes?: string;
}
export interface IAdvisorHubState {
  loginStatus?: LoginStatus;
  loginErrorMessage?: string;
  user?: IHubUser;
  nudge?: INudge;
  nudgeList?: INudgePayload[];
  nudgeTemplateList?: INudgeTemplate[];
  progressStatus?: ProgressStatus;
  sessions?: IHubSessionState;
  alert?: {
    open?: boolean;
    type?: HubAlertType;
    text?: string;
  };
  favorites?: {
    itemType: string;
    itemId: string;
  }[];
  showingOnlyFavorites?: boolean;
  userInfo?: {
    [key: string]: IUserInfoState;
  };
  folders?: {
    storybook?: IFolder[];
    assetl?: IFolder[];
    activeFolderKey?: string;
    expandedFolderKeys?: string[];
    currentContext?: IFolderContext;
    elementsInFolderKeys?: {
      [key: string]: string[];
    };
    asset_library?: IFolder[];
  };
  loadingStatus?: {
    [key: string]: LoadingStatus;
  };
  fileUpload?: {
    files?: IFileToUpload[];
    uploaded?: string[];
    converted?: string[];
  };
  collaboration?: ICollaborationState;
  scene?: ISceneState;
  assetLibrary?: {
    assets?: {
      data?: IAsset[];
      total?: number;
      loading?: boolean;
      hasMore?: boolean;
      errorMessage?: string;
    };
    updatingAsset?: IAsset[];
    popup?: AssetLibraryPopupState;
  };
}

export interface ICollaborationPopup {
  itemId: string;
  context: ICollaboratorContext;
  owner: string;
}

export interface ICollaborationConflictPopup {
  context: ICollaboratorContext;
  remoteItem: ILibraryScene | IStorybook;
  onApproveLocalItem: () => void;
  onApproveRemoteItem?: () => void;
}
export interface ICollaborationState {
  popup?: ICollaborationPopup;
  conflictPopup?: ICollaborationConflictPopup;
}

export enum ProgressStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED'
}

export enum LoginStatus {
  LOGGING_IN = 'LOGGING_IN',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  VERIFY = 'VERIFY',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CONFIRMED_PASSWORD = 'CONFIRMED_PASSWORD',
  LOG_IN = 'LOG_IN',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
}

export interface IProductDetails {
  currentIndex?: number;
  showDescription?: boolean;
  showSpecifications?: boolean;
  arViewer?: {
    open?: boolean;
    viewerUrl?: string;
  };
}

export enum VirtualBoutiqueMode {
  PRESENTATION = 'PRESENTATION',
  COCKPIT = 'COCKPIT',
  NORMAL = 'NORMAL'
}

export interface PanoView {
  viewH?: number;
  viewV?: number;
  fov?: number;
}

export interface AppointmentPopup {
  open: boolean;
  type?: AppointmentType;
  contact?: string;
  enquiry?: string;
}

export interface VideoAppointmentPopup {
  open: boolean;
  contact?: string;
  enquiry?: string;
}
export interface SultanateOfOmanPopup {
  open: boolean;
  contact?: string;
  enquiry?: string;
}

export enum EnquiryType {
  ASK_WATCHMAKER = 'ASK_WATCHMAKER',
  SULTANATE_OF_OMAN = 'SULTANATE_OF_OMAN'
}

export interface EnquiryPopup {
  open: boolean;
  type?: EnquiryType;
  contact?: string;
  enquiry?: string;
}

export enum RDUHudMenu {
  EXIT = 'EXIT',
  EXPRESSIVE_SINGULARITY = 'EXPRESSIVE_SINGULARITY',
  ADRENALINE = 'ADRENALINE',
  LOBBY = 'LOBBY',
  FIVE_AXIS_MILLING = 'FIVE_AXIS_MILLING',
  ELECTRO_EROSION = 'ELECTRO_EROSION',
  WHEELS_POLISHING_WORKSHOPS = 'WHEELS_POLISHING_WORKSHOPS',
  POLISH_OF_THE_EDGES = 'POLISH_OF_THE_EDGES',
  POLISH_OF_THE_WINGS = 'POLISH_OF_THE_WINGS',
  DIMENSION_CONTROLLING = 'DIMENSION_CONTROLLING',
  CERCLAGE = 'CERCLAGE',
  PERLAGE = 'PERLAGE',
  FLAT_MIRROR_POLISH_AND_DETOURING = 'FLAT_MIRROR_POLISH_AND_DETOURING',
  FLAT_MIRROR_POLISH = 'FLAT_MIRROR_POLISH',
  DETOURING = 'DETOURING',
  GARNISH_WORKSHOPS = 'GARNISH_WORKSHOPS',
  GARNISH_WORKSHOPS_OPENING = 'GARNISH_WORKSHOPS_OPENING',
  WATCH_MAKING_WORKSHOPS = 'WATCH_MAKING_WORKSHOPS',
  QLAB = 'QLAB',
  PIRELLI_WHITE = 'PIRELLI_WHITE',
  PIRELLI_RED = 'PIRELLI_RED',
  PIRELLI_YELLOW = 'PIRELLI_YELLOW',
  ETIRAGE = 'ETIRAGE'
}

export interface IHudState {
  showButton?: boolean;
  open?: boolean;
  activeMenu?: RDUHudMenu;
}

export interface IAccessRequest {
  open?: boolean;
  granted?: boolean;
  muteVideo?: boolean;
}

export interface IProductState {
  selectedProductId?: string;
  showingDescriptionForProductId?: string;
  loadedProducts?: IProduct[];
}

export interface IMapSpot extends PanoView {
  scene: string;
}

export interface IMapState {
  open?: boolean;
  selectedRoom?: string;
}

export interface ITeleportSite {
  siteId?: string;
  config?: IVirtualBoutiqueConfig;
  store?: IStore;
}
export interface ITeleportState {
  teleportTo?: ITeleportSite;
  history?: string[];
  showOptions?: boolean;
}

export interface ISSPStorybookState {
  type?: 'search' | 'view';
  sessionId?: string;
  brandId?: string;
  storybookId?: string;
  open: boolean;
}

export interface VirtualBoutiqueState {
  mode?: VirtualBoutiqueMode;
  popup?: IPopupState;
  sceneIds?: string[];
  currentView?: PanoView;
  appointmentPopup?: AppointmentPopup;
  videoAppointmentPopup?: VideoAppointmentPopup;
  sultanateOfOmanPopup?: SultanateOfOmanPopup;
  enquiryPopup?: EnquiryPopup;
  openContactPopup?: boolean;
  openFeedbackPopup?: boolean;
  contactButtonClicked?: boolean;
  journeyConfig?: PanoJourneyConfig;
  chatConfig?: ChatConfig;
  showZoomIndicator?: boolean;
  discoverMore?: PanoDiscoverMore;
  muteBackgroundSound?: boolean;
  backgroundVideoPlayed?: boolean;
  panoLoaded?: boolean;
  hasShownCampaign?: boolean;
  showFullScreenVideo?: boolean;
  fullScreenVideoWithUrl?: {
    show?: boolean;
    url?: string;
  };
  hud?: IHudState;
  showWelcomeToScene?: boolean;
  showVeil?: boolean;
  accessRequest?: IAccessRequest;
  openMusicPlaylist?: boolean;
  activeBackgroundMusicUrl?: string;
  muteBackgroundNoise?: boolean;
  showOutro?: boolean;
  showStudioJoinButton?: boolean;
  lightsOff?: boolean;
  map?: IMapState;
  pixelStreaming?: IPSState;
  sspStorybook?: ISSPStorybookState;
}

export interface IPopupState {
  url: string;
  open: boolean;
  darkHeader?: boolean;
  pageType?: PageView;
}

export interface IOfficialSalesPrices {
  readonly [key: string]: {
    readonly countryCode: string;
    readonly currencyCode: string;
    readonly salesPrice: number;
    readonly productLinkOwnerWebsite: string | null;
  };
}

export interface IViewRange {
  [key: string]: {
    start: number;
    end: number;
  };
}

export interface IWatchSpecification {
  readonly languageCode: string;
  readonly name: string;
  readonly description: string;
  readonly longDescription: string;
  readonly material: string;
  readonly color: string;
  readonly languageLink: string | undefined;
  readonly caseSpecs: string[];
  readonly movementSpecs: string[];
  readonly featuresSpecs: string[];
  readonly dialSpecs: string[];
  readonly strapSpecs: string[];
  readonly story: string;
}

export interface IWatchSpecifications {
  readonly [key: string]: IWatchSpecification;
}

export interface IJewelrySpecification {
  readonly color: string;
  readonly description: string;
  readonly languageCode: string;
  readonly longDescription: string;
  readonly material: string;
  readonly name: string;
  readonly preciousStones: string[];
  readonly size: string[];
  readonly weight: string[];
  readonly width: string[];
  readonly story: string;
  readonly type: string;
  readonly other: string[];
}

export interface IJewelrySpecifications {
  readonly [key: string]: IJewelrySpecification;
}

export interface IHighJewelrySpecification {
  readonly longDescription: string;
  readonly diamonds: string;
  readonly color: string;
  readonly mainStoneShape: string;
  readonly carat: string;
  readonly description: string;
  readonly mainStoneType: string;
  readonly type: string;
  readonly languageCode: string;
  readonly preciousMetalWeight: string;
  readonly mainStoneMaterial: string;
  readonly preciousStones: {
    readonly [key: string]: string | number;
  }[];
  readonly mainStoneCarat: string;
  readonly alliage: string;
}

export interface IHighJewelrySpecifications {
  readonly [key: string]: IHighJewelrySpecification;
}

export interface IBuckleStrapSpecification {
  readonly color: string;
  readonly description: string;
  readonly features: string[];
  readonly languageCode: string;
  readonly material: string;
  readonly name: string;
  readonly size: string;
}

export interface IBuckleStrapSpecifications {
  readonly [key: string]: IBuckleStrapSpecification;
}

export interface IWatchEditions {
  [key: string]: {
    edition: string;
  };
}

export enum ProductType {
  WRISTWATCH = 'WRISTWATCH',
  JEWELLERY = 'JEWELLERY',
  STRAP = 'STRAP',
  BUCKLE = 'BUCKLE',
  SHOES = 'SHOES',
  HIGH_JEWELLERY = 'HIGH JEWELLERY'
}

export interface IComparedProduct {
  readonly id: string;
  readonly type: ProductType | null;
  readonly brandId: string;
}

export interface IProduct {
  readonly brandId: string | null;
  readonly brandName: string | null;
  readonly colorText: string | null;
  readonly countryCode: string | null;
  readonly description: string | null;
  readonly id: string;
  readonly longDescription: string | null;
  readonly modelCode: string;
  readonly modelGroup: string;
  readonly modelName: string;
  readonly gender?: string;
  readonly productColorImageURL: string | null;
  readonly previewImageUrl: string | null;
  readonly type: ProductType | null;
  readonly imageUrls: string[];
  readonly salesPriceSg: number | null;
  readonly currency: string | null;
  readonly offeringPrice: number | null;
  readonly offeringCurrency: string | null;
  readonly officialSalesPrices: IOfficialSalesPrices | null;
  readonly watchSpecifications: IWatchSpecifications | null;
  readonly buckleStrapSpecifications: IBuckleStrapSpecifications | null;
  readonly jewelrySpecifications: IJewelrySpecifications | null;
  readonly highJewelrySpecifications: IHighJewelrySpecifications | null;
  readonly editions: IWatchEditions | null;
  readonly productFamily: string;
}

export interface IInterestedProduct {
  productId: string;
  views: string;
  lastViewed: string;
}

export interface ICredentials {
  sessionCredentials: {
    sessionToken: string;
    awssecretKey: string;
    awsaccessKeyId: string;
  };
  identityId: string;
  expiryDate: string;
}

export interface IProductResponse {
  readonly brand?: string;
  readonly brand_name?: string;
  readonly colortext?: string;
  readonly color?: string;
  readonly description?: string;
  readonly id: string;
  readonly imageUrl?: string;
  readonly type?: ProductType;
  readonly long_description?: string;
  readonly model_code: string;
  readonly model_group: string;
  readonly model_name?: string;
  readonly productColorImageURL?: string;
  readonly product_images?: string[];
  readonly sales_price_sg?: number;
  readonly currency?: string;
  readonly offering_price?: number;
  readonly offeringCurrency?: string;
  readonly officialSalesPrices?: IOfficialSalesPrices;
  readonly watchSpecifications?: IWatchSpecifications;
  readonly jewelrySpecifications?: IJewelrySpecifications;
  readonly highJewelrySpecifications?: IHighJewelrySpecifications;
  readonly buckleStrapSpecifications?: IBuckleStrapSpecifications;
  readonly editions?: IWatchEditions;
  readonly product_family?: string;
  readonly gender?: string;
}

export interface IStoryResponse {
  readonly campaignImgUrl?: string;
  readonly channel?: string[];
  readonly context?: string;
  readonly cta?: string;
  readonly ctaUUID?: string;
  readonly description?: string;
  readonly end?: string;
  readonly endTimestamp?: string;
  readonly htmlURL?: string;
  readonly id?: string;
  readonly occasions?: string[];
  readonly onlyMapping?: boolean;
  readonly originationURL?: string;
  readonly ownerAliasName?: string;
  readonly ownerAvatar_picture?: string;
  readonly ownerFirstName?: string;
  readonly ownerId?: string;
  readonly ownerLastName?: string;
  readonly ownerRating?: string;
  readonly ownerStatusText?: string;
  readonly pictures?: string[];
  readonly priority?: number;
  readonly products?: string[];
  readonly s3VideoURL?: string;
  readonly start?: string;
  readonly startTimestamp?: string;
  readonly status?: string;
  readonly storyDetails?: string;
  readonly tags?: string;
  readonly tagList?: string[];
  readonly target?: string[];
  readonly timestamp?: string;
  readonly title?: string;
  readonly chineseTitle?: string;
  readonly type?: string;
  readonly videoURL?: string;
  readonly flipPosterUrl?: string;
  readonly flipVideoUrl?: string;
  readonly blurbImageUrl?: string;
  readonly brand?: string;
  readonly createdAt?: string;
  readonly contentChinese?: string;
  readonly contentHtmlChinese?: string;
  readonly countryTags?: string[];
  readonly submittedAt?: string;
  readonly publishedAt?: string;
  readonly deactivatedAt?: string;
  readonly createdBy?: string;
  readonly modifiedBy?: string;
  readonly modifiedAt?: string;
  readonly originalPublicationDate?: string;
  readonly visibility?: string;
  readonly duration?: number;
  readonly site?: string | null;
}

export interface IInspiration {
  readonly id: string;
  readonly sender: string | null;
  readonly senderName: string | null;
  readonly senderImgUrl: string | null;
  readonly flipImageUrl: string | null;
  readonly title: string | null;
  readonly context: string | null;
  readonly description: string | null;
  readonly type: string | null;
  readonly videoUrl: string | null;
  readonly htmlUrl: string | null;
  readonly productIds: string[];
  readonly tagList?: string[];
  readonly priority?: number;
  readonly createdAt?: string | null;
  readonly submittedAt?: string | null;
  readonly createdBy?: string | null;
  readonly modifiedAt?: string | null;
  readonly visibility?: string | null;
  readonly duration?: string | number | null;
  readonly site?: string | null;
  readonly campaignImgUrl?: string | null;
  readonly sceneId?: string | null;
  readonly scene?: ILibraryScene | null;
}

export interface StoreBrand {
  readonly brandId: string;
  readonly item_types: string[];
}

export interface IStore {
  readonly id: string;
  readonly brands: StoreBrand[];
  readonly name: string;
  readonly mall: string;
  readonly description: string;
  readonly whatsAppShareMessage: string;
  readonly chatNowNumber: string;
  readonly countryCode: string;
  readonly city?: string;
  readonly languageCode?: string;
  readonly email: string;
  readonly currencyCode: string;
  readonly openingHours: {
    readonly sunday: string;
    readonly monday: string;
    readonly tuesday: string;
    readonly wednesday: string;
    readonly thursday: string;
    readonly friday: string;
    readonly saturday: string;
  };
}

export interface AnalyticEvent {
  readonly type: string;
  payload: any;
  readonly event_timestamp: number;
  readonly identityId?: string;
  readonly storeId?: string;
  readonly sourceSystem: 'TOUCH_WEB' | 'SALES_HUB';
  readonly uuid?: string;
  readonly organisationId?: string;
}

export interface IVirtualBoutiqueContact {
  name: string;
  nameKey?: string;
  email?: string;
  cc?: string;
  bcc?: string;
  whatsappNumber?: string;
  callNumber?: string;
  emailSubject?: string;
  lineId?: string;
  zaloNumber?: string;
  activeDays?: [number];
}

export interface IVirtualBoutiqueAppointmentContact {
  id: string;
  boutiqueMeetingIdentifier: string;
  name: string;
  nameKey?: string;
  email: string;
  cc?: string;
}

export interface IVirtualBoutiqueShopContact {
  bcc?: string;
  cc?: string;
  email: string;
  id: string;
  name: string;
  deliveryMethod: DeliveryMethod;
  nameKey?: string;
}

export interface ITutorialStep {
  instruction: string;
  videoPath: string;
}

export enum BoutiqueCampaignType {
  POPUP = 'POPUP',
  IMAGE = 'IMAGE'
}

export interface IBoutiqueImageCampaign {
  linkUrl?: string;
  mobileImageUrl?: string;
  tabletImageUrl?: string;
  desktopImageUrl?: string;
  popupPayload?: IBoutiquePopupCampaign;
}

export interface IBoutiquePopupCampaign {
  pageView: PageView;
  pageId: string;
}

export interface IBoutiqueCampaign {
  type?: BoutiqueCampaignType;
  imageCampaign?: IBoutiqueImageCampaign;
  popupCampaign?: IBoutiquePopupCampaign;
  secondsBeforeShow?: number;
  showOnSceneId?: string;
}

export interface IVirtualBoutiquePano {
  swf: string;
  xml: string;
  basepath: string;
  jsLibUrl?: string;
}

export interface ILanguageOption {
  code: string;
  display: string;
  default?: boolean;
}

export interface IStrictProductDisplayConfig {
  //key = model code
  [key: string]: {
    officialSalesPrice?: string;
    disableOrderButton?: boolean;
  };
}

export interface IVirtualBoutiqueLiteConfig {
  bannerImageUrl?: string;
  bannerImageUrlMobile?: string;
}
export interface IMeetingVideo {
  welcomeVideoUrl?: string;
  closingVideoUrl?: string;
  allowSkip?: boolean;
}

export interface IOpeningHoursByWeekday {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

export type ProductsFilter = (products: IProduct[]) => IProduct[];

export interface IProductsFilterList {
  productBlacklist?: string[];
  productWhitelist?: string[];
}
export interface IOverlay {
  imageUrl: string;
  portraitImageUrl?: string;
  initShow?: boolean;
}
export interface UnrealCommandAction {
  action?: string;
  id?: string;
  type?: PageView;
  videoUrl?: string;
  reply?: string;
  delay?: number;
}
export interface UnrealCommandConfig {
  [key: string]: UnrealCommandAction;
}
export interface IVirtualBoutiqueConfig {
  boutiqueName: string;
  brandName: string;
  brandId: string;
  openGraphImage: string;
  openGraphTitle?: string;
  openingHours: string[];
  canvasBasedExperience?: boolean;
  unRealBasedExperience?: boolean;
  unRealInitialScene?: string;
  matchMakerAddress?: string;
  unRealCommands?: UnrealCommandConfig;
  description?: string;
  publicHolidayOpeningHours?: string[];
  weekendOpeningHours?: string[];
  visitHours?: string[];
  publicHolidayVisitHours?: string[];
  weekendVisitHours?: string[];
  openingHoursByWeekday?: IOpeningHoursByWeekday;
  appointmentSlotDurationInMinutes?: number;
  timezoneGMTOffset?: number;
  timezoneName?: string;
  phoneCountryCode?: string;
  productBlacklist?: string[];
  productWhitelist?: string[];
  contacts: IVirtualBoutiqueContact[];
  appointmentContacts: IVirtualBoutiqueAppointmentContact[];
  logoUrl: string;
  lightLogoUrl: string;
  landingImageUrl: string;
  languages?: ILanguageOption[];
  mobileLandingImageUrl?: string;
  pano?: IVirtualBoutiquePano;
  entitlement?: IVirtualBoutiqueEntitlement;
  tutorials?: {
    desktop: ITutorialStep[];
    mobile: ITutorialStep[];
  };
  newsLetterSubscription?: string;
  campaign?: IBoutiqueCampaign;
  feedbackOpenDelay?: number;
  defaultChatMessageKey?: string;
  defaultShareMessageKey?: string;
  pickOfTheMonth?: string;
  shopNowContacts?: IVirtualBoutiqueShopContact[];
  strictProductDisplay?: IStrictProductDisplayConfig;
  vbLite?: IVirtualBoutiqueLiteConfig;
  presentationBackgroundImageUrl?: string;
  meetingVideo?: IMeetingVideo;
  loungeTagLine?: string;
  passcode?: string;
  overlay?: IOverlay;
  countdownBg?: string;
  virtualBackgrounds?: string[];
  theme?: {
    color?: {
      button?: string;
      accent?: string;
      primary?: string;
      secondary?: string;
      link?: string;
    };
    font?: {
      headerFont?: string;
      headerFontUrl?: string;
      bodyFont?: string;
      bodyFontUrl?: string;
    };
  };
  isNotPreConfigured?: boolean;
  fromStorybook?: boolean;
  parentBrandId?: string;
}

export interface IVirtualBoutiqueEntitlement {
  productSearch?: boolean;
  productCompare?: boolean;
  journey?: boolean;
  chat?: boolean;
  productImageZoom?: boolean;
  wishlist?: boolean;
  cockpit?: boolean;
  productAppointment?: boolean;
  feedback?: boolean;
  friendsShopping?: boolean;
  toggleSound?: boolean;
  walkthroughMirrorScroll?: boolean;
  pickOfTheMonth?: boolean;
  shoppingCart?: boolean;
  shareScreen?: boolean;
  meetOnly?: boolean;
  hideProductFilter?: boolean;
}

export interface ProductSearchQuery {
  searchAttributes: {
    keywords: string;
    startIndex: number;
    size: number;
    queryString: string;
  };
  flattenResult: boolean;
}

export interface ProductFamily {
  readonly family: string;
  readonly url: string;
  readonly product: string;
  readonly displayName: {
    en: string;
    zh: string;
    ar?: string;
  };
}

export interface InspirationsSearchQuery {
  keywords: string;
  startIndex: number;
  size: number;
  queryString: string;
  languageCode?: string;
}

export interface CloudSearchFilter {
  readonly displayName: {
    readonly en: string;
    readonly ar?: string;
  };
  readonly cloudSearchField: string;
  cloudSearchGroup: string;
}

export interface ProductSearchFilter {
  readonly filterName: CloudSearchFilter;
  readonly filterOptions: CloudSearchFilter[];
}

export interface ProductGrouping {
  readonly name: string;
  readonly products: string[];
}

export interface PanoJourneyOption {
  label: string;
  labelLangKey?: string;
  sceneId?: string;
  options?: PanoJourneyOption[];
}

export interface PanoJourneyScene {
  readonly message?: string;
  readonly messageLangKey?: string;
  readonly subtitle?: string;
  readonly subtitleLangKey?: string;
  readonly sceneId?: string;
  readonly options?: PanoJourneyOption[];
}

export interface PanoJourneyConfig {
  readonly [key: string]: PanoJourneyScene;
}

export interface PanoDiscoverMore {
  type?: PageView;
  id?: string;
  open: boolean;
}

export interface ChatMessageOption {
  content: string;
  contentLangKey?: string;
  nextId: number | string;
  sceneId?: string;
  popupType?: PageView;
  popupId?: string;
  inlinePopup?: ChatPopupType;
  payload?: any;
  viewAngle?: PanoView;
  style?: {
    [key: string]: string | number;
  };
  nextDelayInMS?: number;
}

export interface ChatMessageConfig {
  id: number | string;
  content?: string;
  contentLangKey?: string;
  multiContent?: {
    top: string;
    topLangKey?: string;
    center: string;
    centerLangKey?: string;
    bottom: string;
    bottomLangKey: string;
  };
  nextId?: number | string;
  options?: ChatMessageOption[];
  popupType?: PageView;
  popupId?: string;
  nextDelayInMS?: number;
  style?: {
    [key: string]: string | number;
  };
}

export interface ChatConfig {
  chatbotName: string;
  chatbotAvatarUrl: string;
  showOnAdvisorCockpit?: boolean;
  messages: ChatMessageConfig[];
}

export interface LiveChatConfig {
  chatbotName: string;
  chatbotAvatarUrl?: string;
  showOnAdvisorCockpit?: boolean;
  messages: ChatMessageConfig[];
}

export interface IWishlistItem {
  itemId: string;
  itemType: WishlistType;
  size?: string;
}

export enum WishlistType {
  PRODUCT = 'PRODUCT',
  INSPIRATION = 'INSPIRATION'
}

export enum InvitationStatus {
  IN_PROGRESS = 'in_progress',
  INVITATION_PENDING = 'invitation_pending',
  ACCEPTED = 'accepted'
}

export interface IWishlistPayload {
  id: string;
  identityId: string;
  description: string;
  name: string;
  store: string;
  brand: string;
  wishlistItems: IWishlistItem[];
  createdAt?: string | Date;
  modifiedAt?: string | Date;
  surname?: string;
  status?: string;
  email?: string;
  phone?: string;
  acceptedTermsOfUse?: boolean;
  region?: string;
}

export interface IAddUpdateDeleteTeam {
  team: ITeam;
  type: 'create' | 'update' | 'delete';
}
export interface ITeam {
  id: string;
  name: string;
  brand: string;
  createdBy?: string | null;
  createdAt: string;
  members: string[];
  modifiedBy: string;
  modifiedAt?: string | null;
  description: string;
}
export interface ITeamDetail {
  id: string;
  name: string;
  brand: string;
  createdBy?: string | null;
  createdAt: string;
  members: IUser[];
  modifiedBy: string;
  modifiedAt?: string | null;
  description: string;
}
export interface INewInviteUser {
  email: string;
  spaceId: string;
  brandId: string;
  role: RoleEntitlement;
  brandUrl: string;
}

export enum SocialMediaType {
  PAGE = 'page',
  USER = 'personal'
}
export interface ISocialMedia {
  token: string;
  id: string;
  type: SocialMediaType;
}
export interface IUser {
  id: string;
  alias?: string;
  email?: string;
  enabled?: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  user_name?: string;
  mobilePhoneCountryCode?: string;
  mobileNumber?: string;
  userType?: string;
  createdAt?: string;
  modifiedAt?: string;
  language?: string;
  entitlements?: IEntitlement[];
  avatar_picture?: string;
  storeId?: string;
  storeName?: string;
  statusText?: InvitationStatus;
  role?: string;
  streamTokens?: string[];
  socialMedia?: ISocialMedia[];
}

export enum MeetUserPermissionType {
  TEAM = 'TEAM',
  STORYBOOK = 'STORYBOOK',
  CALENDAR = 'CALENDAR',
  PROFILE = 'PROFILE',
  USER_MANAGEMENT = 'USER_MANAGEMENT'
}

export interface IMeetUserPermission {
  name: MeetUserPermissionType;
  description: string;
}

export interface IMeetUser {
  id: string;
  modifiedAt?: string;
  permissions?: IMeetUserPermission[];
  createdAt?: string;
  description: string;
  createdBy?: string;
  name?: string;
  modifiedBy?: string;
}

export interface WritableUserAttributes {
  alias?: string;
  email?: string;
  mobilePhoneCountryCode?: string;
  mobileNumber?: string;
}

export enum ChatPopupType {
  VISIT_APPOINTMENT = 'VISIT_APPOINTMENT',
  VB_POPUP_CONTENT = 'VB_POPUP_CONTENT',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  CONTACT = 'CONTACT',
  ENQUIRY = 'ENQUIRY',
  CUSTOM_ACTION = 'CUSTOM_ACTION',
  MAP = 'MAP'
}

export enum AppointmentType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL_WALKTHROUGH = 'VIRTUAL_WALKTHROUGH',
  EXTERNAL_URL = 'EXTERNAL_URL'
}

export interface IFeedback {
  readonly userRating: number;
  readonly maxRating: number;
  readonly comment?: string;
}

export enum DeliveryMethod {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICK_UP'
}
export enum ContactMethod {
  TEXT = 'SMS/Whatsapp',
  CALL = 'Call',
  EMAIL = 'Email'
}

export enum TimeSlot {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  EVENING = 'Evening'
}

export interface IShoppingCartItem extends IProduct {
  quantity: number;
  size?: string;
}

export interface IShoppingCartItemDisplay {
  id: string;
  modelName: string;
  productFamily?: string;
  modelCode?: string;
  quantity: number;
  price?: number;
  totalPrice?: number;
  formattedPrice?: string;
  formattedTotalPrice?: string;
  imageUrl: string | null;
  currencyCode?: string;
}

export interface IAddressForm {
  [key: string]: string;
}

export interface IShoppingFormData {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  shopNowContact?: IVirtualBoutiqueShopContact | null;
  acceptedTermsOfUse?: boolean | false;
  subscribeNews?: boolean;
  preferredContact?: ContactMethod | null;
  preferredDelivery?: DeliveryMethod | null;
  giftWrapping?: boolean;
  giftNote?: string;
  date?: Date;
  time?: string | null;
  phoneCountryCode?: string | null;
}

export interface ICheckoutFormData {
  salutation?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  addressForm?: IAddressForm;
  useShippingAsBillingAddress?: boolean;
  billingAddressForm?: IAddressForm;
  address?: string | null;
  shopNowContact?: IVirtualBoutiqueShopContact | null;
  acceptedTermsOfUse?: boolean | false;
  subscribeNews?: boolean;
  preferredContact?: ContactMethod | null;
  preferredDelivery?: DeliveryMethod | null;
  giftWrapping?: boolean;
  giftNote?: string;
  date?: Date;
  time?: string | null;
  phoneCountryCode?: string | null;
}

export interface IBillingDescriptor {
  name: string | null;
  city: string | null;
}
export interface IShoppingFormPayload {
  acceptedTermsOfUse?: boolean;
  addressText?: string;
  address?: IAddressForm | string;
  shopNowContactId?: string;
  cartItems?: {
    currency: string;
    itemPrice: number;
    productId: string;
    quantity: number;
    totalPrice: number;
  }[];
  createdAt?: string;
  createdBy?: string;
  currency?: string;
  date?: string;
  deliveryFee?: string;
  email?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  preferredContact?: string;
  preferredDelivery?: string;
  storeId?: string;
  subscribeNews?: boolean;
  subTotal?: number;
  time?: string;
  totalPrice?: number;
  languageCode: string;
}

export interface ICheckoutPayload {
  id?: string;
  storeId?: string;
  brand?: string;
  token?: string;
  amount?: number;
  currency?: string;
  customer?: {
    email: string;
    name: string;
  };
  billingDescriptor?: IBillingDescriptor;
  shipping?: {
    address: IAddressForm;
    phone: {
      countryCode: string;
      number: string;
    };
  };
  subscribeNews?: boolean;
  acceptedTermsOfUse?: boolean;
  checkoutContactId?: string;
  deliveryFee?: string;
  cartItems?: {
    currency: string;
    itemPrice: number;
    productId: string;
    quantity: number;
    totalPrice: number;
  }[];
  preferredDelivery?: string;
  preferredContact?: string;
  preferredLanguage?: string;
  subTotal?: number;
  totalPrice?: number;
  createdAt?: string | Date;
  createdBy?: string;
  modifiedAt?: string | Date;
  modifiedBy?: string;
  giftNote?: string;
  giftWrapping?: boolean;
}

export enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

export enum CheckoutStatus {
  INITIAL = 'CHECKOUT',
  ENTER_PAYMENT_DETAILS = 'PAY NOW',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED = 'PLEASE TRY AGAIN'
}

export enum CheckoutPaymentStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED'
}

export enum CheckoutStep {
  CART = 'CART',
  DELIVERY_ADDRESS = 'DELIVERY ADDRESS',
  PAYMENT = 'PAYMENT',
  ORDER_SUMMARY = 'ORDER SUMMARY',
  THANK_YOU = 'THANK YOU'
}

export enum LoadingStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED'
}

export interface CheckoutDetails {
  [key: string]: any;
}

export interface ICheckoutState {
  step?: CheckoutStep;
  status?: CheckoutStatus;
  token?: string | null;
  orderId?: string | null;
  details?: CheckoutDetails;
  paymentStatus?: CheckoutPaymentStatus;
}

export interface IShoppingCart {
  open?: boolean;
  sendStatus?: EmailSentState;
  checkout?: ICheckoutState;
  items?: IShoppingCartItem[];
  deliveryFee?: number;
  formData?: IShoppingFormData;
  checkoutFormData?: ICheckoutFormData;
}

export interface IAdvisorHubConfig {
  pageName: string;
  brandId: string;
  brandName: string;
}

export interface ICredentials {
  sessionCredentials: {
    sessionToken: string;
    awssecretKey: string;
    awsaccessKeyId: string;
  };
  identityId: string;
  expiryDate: string;
}

export type INudgeItem = IInspiration | IProduct;

export enum NudgeSaveStatus {
  INITIAL = 'INITIAL',
  SAVING = 'SAVING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum NudgeStatus {
  INVITATION_CREATED = 'INVITATION_CREATED',
  INVITATION_OPENED = 'INVITATION_OPENED',
  INVITATION_SENT = 'INVITATION_SENT',
  AWAITING_WALKTHROUGH = 'AWAITING_WALKTHROUGH',
  JOINED_WALKTHROUGH = 'JOINED_WALKTHROUGH',
  LEFT_WALKTHROUGH = 'LEFT_WALKTHROUGH'
}

export enum NudgeItemType {
  PRODUCT = 'product',
  STORY = 'story'
}

export enum NudgeCreationType {
  NEW = 'NEW',
  COPY = 'COPY',
  CREATE_FROM_TEMPLATE = 'CREATE_FROM_TEMPLATE',
  NEW_TEMPLATE = 'NEW_TEMPLATE',
  COPY_TEMPLATE = 'COPY_TEMPLATE',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE'
}

export interface INudge {
  id?: string;
  name?: string;
  title?: string;
  content?: INudgeItem[];
  storeId?: string;
  createdBy?: string;
  createdAt?: string;
  meetingUrlAdvisor?: string;
  meetingUrlClient?: string;
  status?: NudgeStatus;
  modifiedAt?: string;
  modifiedBy?: string;
  customerId?: string;
  customerEmail?: string;
  customerPhoneCountryCode?: string;
  customerPhoneNumber?: string;
  advisorId?: string;
  saveStatus?: NudgeSaveStatus;
  languageCode?: string;
  hidePrice?: boolean;
  hideRecommendations?: boolean;
  hideVariations?: boolean;
  hideReferences?: boolean;
  currency?: string;
  countryCode?: string;
  description?: string;
  brand?: string;
  ogDescription?: string;
  ogTitle?: string;
  ogImage?: string;
  externalDomain?: string;
  contactId?: string;
  source?: string;
  medium?: string;
}

export interface INudgeContentFavorites {
  id?: string;
  identityId?: string;
  store?: string;
  brand?: string;
  description?: string;
  createdBy?: string;
  createdAt?: string;
  status?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  favouriteItems?: {
    itemId: string;
    itemType: string;
  }[];
}

export interface INudgeTemplate {
  id?: string;
  storeId?: string;
  brand?: string;
  title?: string;
  content?: {
    id: string;
    type: NudgeItemType;
  }[];
  description?: string;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  languageCode?: string;
  currency?: string;
  hidePrice?: boolean;
  advisorId?: string;
}

export interface INudgeItemPayload {
  id: string;
  type: NudgeItemType;
}
export interface INudgePayload {
  id: string;
  name: string;
  title?: string;
  content: INudgeItemPayload[];
  storeId: string;
  createdBy: string;
  createdAt?: string;
  meetingUrlAdvisor?: string;
  meetingUrlClient?: string;
  status?: NudgeStatus;
  modifiedAt?: string;
  modifiedBy?: string;
  customerId?: string;
  customerEmail?: string;
  customerPhoneCountryCode?: string;
  customerPhoneNumber?: string;
  advisorId?: string;
  languageCode?: string;
  hidePrice?: boolean;
  currency?: string;
  email?: string;
}

export interface NudgeThumbProps {
  content: INudgeItem;
  className: string;
  onClick: (path: string) => void;
}

export interface ICreateNudgePayload {
  advisorId: string;
  brandId: string;
  contactId: string;
  currency: string;
  languageCode: string;
  storeId: string;
  content: INudgeItemPayload[];
  externalDomain: string;
  countryCode: string;
  createdBy: string;
  hidePrice: boolean;
  hideRecommendations: boolean;
  hideVariations: boolean;
  hideReferences: boolean;
  title: string;
  medium: string;
  source: string;
  campaign: string;
  name: string;
  ogDescription?: string;
  ogTitle?: string;
  ogImage?: string;
}

export enum Source {
  Showroom = 'showroom',
  Nudge = 'nudge',
  AdvisorHub = 'advisorHub',
  Connect = 'connect',
  Session = 'session',
  Chat = 'chat',
  SSP = 'ssp',
  Storybook = 'storybook'
}

export interface IProductAccessories {
  buckles?: IProduct[];
  straps?: IProduct[];
  dials?: IProduct[];
  strapBucklePairs?: {
    [key: string]: string[];
  };
  selectedStrap?: IProduct;
  selectedBuckle?: IProduct;
  selectedDial?: IProduct;
  activeSection?: string;
}

export interface IPrivateAppointmentData {
  id: string | null;
  storeId: string | null;
  date: string | null;
  time: string | null;
  startTime: string | null;
  endTime: string | null;
  timezone: string | null;
  name: string | null;
  customerId: string | null;
  customerEmail: string | null;
  customerPhoneCountryCode: string | null;
  customerPhoneNumber: string | null;
  advisorId: string | null;
  inviteId: string | null;
  preferredLanguage: string | null;
  advisorName: string;
  createdAt?: string | null;
  createdBy?: string | null;
  modifiedAt?: string | null;
  modifiedBy?: string | null;
  status?: string | null;
}

export interface IReservationItems {
  productId: string;
  size: string;
  quantity: number;
  itemPrice: number;
  currency: string;
  totalPrice: number;
}

export interface IReservationCustomerInfo {
  customerId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  phoneCountryCode: string;
  preferredLanguage: string;
}

export interface IReservationPayload {
  id: string;
  reservationDate: string | Date;
  reservationStatus: string;
  storeId: string;
  reservedItems: IReservationItems[];
  subTotal: number;
  totalPrice: number;
  createdAt: string | Date;
  createdBy: string | Date;
  modifiedAt?: string | Date;
  modifiedBy?: string | Date;
  status: string;
  advisorId?: string;
  inviteId?: string;
  sessionId?: string;
  customerInfo: IReservationCustomerInfo;
  currency: string;
}

export interface ICreateUserPayload {
  username: string;
  storeId: string;
  attributes: {
    email: string;
    email_verified: 'true' | 'false';
  };
}

export interface RequestClientContext {
  NUDGE?: string;
  SESSION?: string;
  STORE?: string;
  USER?: string;
}

export interface IBrowsingHistory {
  readonly rank: number;
  readonly storeId: string;
  readonly sessionId: string;
  readonly cognito_id: string;
  readonly event_datetime: string;
  readonly event_type: BrowsingHistoryEventType | string;
  readonly pageType: string;
  readonly productId: string;
  readonly modelCode: string;
  readonly durationInMs: string;
  readonly url: string;
}

export enum BrowsingHistoryEventType {
  DID_VIEW_PAGE = 'DID_VIEW_PAGE',
  EYEBALL_TIME = 'EYEBALL_TIME'
}

export interface ISharingEntity {
  content: string;
  type: ChatMessageType;
}

export interface JitsiVideoTrackEffect {
  backgroundType: 'blur' | 'image' | 'desktop-share' | 'none';
  enabled: boolean;
  blurValue?: number;
  selectedThumbnail?: string;
  url?: string;
  backgroundEffectEnabled?: boolean;
}

export interface ISspCredentials {
  identityId: string;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}
export enum ViewportType {
  SM_PORTRAIT = 'SM_PORTRAIT',
  SM_LANDSCAPE = 'SM_LANDSCAPE',
  MD_PORTRAIT = 'MD_PORTRAIT',
  NARROW_LANDSCAPE = 'NARROW_LANDSCAPE',
  MD_LANDSCAPE = 'MD_LANDSCAPE'
}

export interface LayoutInfo {
  isMobile: boolean;
  width: number;
  height: number;
  isPortrait: boolean;
}

interface Dimension {
  width?: number;
  height?: number;
}

interface Position {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export interface MeetingLayoutSpec {
  thumbnailParticipant: Dimension;
  thumbnailPresenter?: Dimension;
  thumbnailSelf?: Dimension;
  thumbnailRadius?: number;
  thumbnailSpacing?: number;
  participantWrapper?: Dimension;
  presenterWrapper?: Dimension;
  videosWrapper?: Dimension;
  contentArea?: Position;
}

export interface ISearchResult<T> {
  readonly results: T[];
  readonly originalResults: any[];
  readonly totalCount: number;
  readonly lastIndex: number;
}

export enum StoryBookLayoutPage {
  SEARCH = 'STORYBOOK.SEARCH',
  DESIGNER = 'STORYBOOK.DESIGNER',
  SORTER = 'STORYBOOK.SORTER',
  PREVIEW = 'STORYBOOK.PREVIEW'
}

export type IStorybookVisibility = 'hide' | 'private' | 'team';
//401 mean un unauthorized - storybook have passcode and user not enter it yet
export type IStorybookStatus =
  | 'draft'
  | 'published'
  | 'deleted'
  | 'activated'
  | '401';
export type IStoryBookPageType =
  | 'image'
  | 'video'
  | 'product'
  | 'file'
  | 'scene';

export interface IStorybookPage {
  id?: string;
  title?: string;
  subtitle?: string;
  settings?: string;
  thumbnail?: string;
  url?: string;
  pageType?: IStoryBookPageType;
  modifiedAt?: string;
  hasChanged?: boolean;
  sceneDetails?: {
    name?: string;
    description?: string;
  };
  metadata?: {
    silence?: string;
    portraitUrl?: string;
  };
  overlay?: string;
  editor?: IPolotnoJSON;
  //for scene name
  name?: string;
}

export interface IStorybookPageForPublish {
  id: string;
  title: string;
  subtitle: string;
  settings: string;
  thumbnail: string;
  url: string;
  pageType: IStoryBookPageType;
  metadata: {
    silence?: string;
    portraitUrl?: string;
  };
}

export interface IStorybookVersion {
  version: number;
  versionId: string;
  publishedBy: string | null;
  publishedAt: string | null;
  status: IStorybookStatus;
}

export interface StorybookVersionInfo {
  latestVersion: number;
  latestActivatedVersion: number;
  publishedVersion: number;
  draftVersion: number;
}

export interface IStorybookStats {
  versionInfo: StorybookVersionInfo;
  isPublished: boolean;
  canPublish: boolean;
}
export interface SBMetadata {
  converted: string;
  conversionerror?: string;
  silence?: 'true' | 'false';
}

export interface IStorybook {
  id?: string;
  brand?: string;
  title?: string;
  subtitle?: string;
  color?: string;
  duration?: number;
  settings?: string;
  status?: IStorybookStatus;
  pages?: IStorybookPage[];
  visibility?: IStorybookVisibility;
  visibilityScope?: string[];
  version?: number;
  createdBy?: string;
  createdAt?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  publishedBy?: string;
  publishedAt?: string;
  featuredImage?: string;
  availableVersions?: IStorybookVersion[];
  keys?: string[];
  stats?: IStorybookStats;
  tags?: string[];
  metadata?: SBMetadata;
  editor?: IPolotnoJSON;
}

export type LayerActionEmailPayload = {
  address?: string;
  subject?: string;
};

export type LayerActionLocationAddress = {
  location: string;
  address: string;
};

export type LayerActionLinkPayload = {
  address?: string;
  openInPopup?: boolean;
  locationBasedEnabled?: boolean;
  locationAdresses?: LayerActionLocationAddress[];
};

export type LayerActionContentPayload = {
  contentId?: string;
  contentType?: 'product' | 'storybook' | 'scene';
  contentThumbnail?: string;
  contentTitle?: string;
};
export type LayerActionPayload = LayerActionContentPayload &
  LayerActionEmailPayload &
  LayerActionLinkPayload & {
    title?: string;
  };

export type LayerActionType = 'email' | 'link' | 'share' | 'content';
export interface LayerActions {
  type?: LayerActionType;
  payload?: LayerActionPayload;
}

export interface ISceneProduct {
  id: string;
  field: string;
  defaultValue: string;
  type: 'image' | 'text';
}
export interface AnyKey {
  [key: string]: any;
}
export interface SceneLayerSettings extends AnyKey {
  type: 'image' | 'video' | 'action' | 'gif' | 'product';
  url: string;
  x: number;
  y: number;
  width: number;
  height: number;
  videoLoop?: boolean;
  product?: ISceneProduct;
  muteParticipant?: boolean;
  autoplay?: boolean;
  audio?: boolean;
  transparent?: boolean;
  opacity?: number;
  action?: LayerActions;
  flipX?: boolean;
  flipY?: boolean;
  rotation?: number;
}

export interface ISceneLayer {
  desktop?: SceneLayerSettings[];
  mobile?: SceneLayerSettings[];
}

export interface IFont {
  fontFamily: string;
  url: string;
}
export interface IStorybookSettings {
  settingJSONVersion?: string | number;
  settingStorybookVersion?: string | number;
  color?: string;
  duration?: number;
  autoplay?: boolean;
  videoLoop?: boolean;
  muteParticipant?: boolean;
  videoUrl?: string;
  background?: string;
  text?: string;
  layer?: ISceneLayer;
  portraitUrl?: string;
  portraitThumbnail?: string;
  featuredImage?: string;
  exportable?: boolean;
  type?: 'image' | 'video' | 'layer';
  language?: TLanguage;
  customFont?: IFont[];
}

export interface PreviewState {
  onClose: () => void;
  content: any;
}

export interface IStorybookFile {
  id: string;
  title: string;
  subtitle?: string;
  thumbnails: string[];
  pages: string[];
}

export enum PageContentView {
  STORYBOOK_VIEWER = 'STORYBOOK_VIEWER',
  STORYBOOK_LANDING = 'STORYBOOK_LANDING',
  STORYBOOK_PASSCODE = 'STORYBOOK_PASSCODE',
  DOCUMENT_VIEWER = 'DOCUMENT_VIEWER',
  CONTENT_PAGE = 'CONTENT_PAGE',
  PRODUCT_SPOT = 'PRODUCT_SPOT',
  INSPIRATION_SPOT = 'INSPIRATION_SPOT',
  SCENE_SPOT = 'SCENE_SPOT',
  SCENE = 'SCENE',
  ASSET_VIEWER = 'ASSET_VIEWER'
}

interface EyeballLoggerPayloadForPageView {
  view: PageView;
  id: string;
  organisationId?: string;
  uuid?: string;
}

interface EyeballLoggerPayloadForPageContentView {
  view: PageContentView;
  id: string;
  title?: string;
  contentUrl?: string;
  contentTitle?: string;
  contentSubtitle?: string;
  pageNumber?: number;
  pageId?: string;
  spotId?: string;
  portrait?: boolean;
  organisationId?: string;
  uuid?: string;
}

export type EyeballTimeView = PageView | PageContentView;

export type EyeballLoggerPayload<T extends EyeballTimeView> = T extends PageView
  ? EyeballLoggerPayloadForPageView
  : EyeballLoggerPayloadForPageContentView;

export type IDevicePermission = Record<
  'audio' | 'video',
  DevicePermissionStatus
>;
export type IFolderContext =
  | 'storybook'
  | 'nudge'
  | 'gallery'
  | 'library'
  | 'scenetemplate'
  | 'asset_library';

export interface IFolderKey {
  id: string;
  key: string;
  settings?: { type?: string };
}

export interface IFolder {
  id?: string;
  uuid?: string;
  context?: IFolderContext;
  keys?: IFolderKey[];
  createdBy?: string;
  createdAt?: string;
  modifiedBy?: string;
  modifiedAt?: string;
}
export interface IStorybookPageAnalyticsData {
  storybookId: string;
  pageNumber?: number;
  pageId?: string;
  views: number;
  totalDurationInMs?: number;
  avgDurationInMs?: number;
  score?: number;
}

export interface IStorybookAnalyticsUserViewsData {
  cognitoId: string;
  name: string;
  views: number;
  totalDurationInMs?: number;
  avgDurationInMs?: number;
  longestDurationInMs?: number;
  pageId?: string;
}

export interface ILibraryVersion {
  activatedAt?: string;
  activatedBy?: string;
  status?: IStorybookStatus;
  version?: number;
  versionId?: string;
}

export interface ILibrarySceneContent {
  name?: string;
  description?: string;
  title?: string;
  subtitle?: string;
  settings?: string;
  url?: string;
  thumbnail?: string;
  visibility?: IStorybookVisibility;
  visibilityScope?: string[];
  overlay?: string;
  metadata?: {
    silence?: string;
    portraitUrl?: string;
  };
}

export type LibrarySceneType = 'scene' | 'template';

export type IPexelsUploadStatus = Record<string, string>;

export interface ILibraryScene {
  id?: string;
  content?: ILibrarySceneContent;
  keys?: string[];
  tags?: string[];
  status?: IStorybookStatus;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  activatedAt?: string;
  activatedBy?: string;
  availableVersions?: ILibraryVersion[];
  version?: number;
  editor?: IPolotnoJSON;
  type?: LibrarySceneType;
  isTemplate?: Boolean
}

export interface ILibraryScenePayload {
  id: string;
  content: ILibrarySceneContent;
  keys: string[];
  tags: string[];
  status: IStorybookStatus;
  createdBy: string;
  modifiedBy: string;
  type?: LibrarySceneType;
}

export interface ITagsVisibilityPayload {
  id: string;
  tags: string[];
  visibility: IStorybookVisibility;
  visibilityScope: string[];
  createdBy: string;
}

export interface IMultilingualVideoUrls {
  [language: string]: string;
}

export interface ICognitoUser {
  sub: string;
  email_verified: boolean;
  'cognito:username': string;
  'custom:job_title': string;
  'custom:phone_country_code': string;
  given_name: string;
  'custom:onboarding_status': string;
  'custom:account_id': string;
  'custom:onboarding_step': string;
  'custom:stripeCustomerId': string;
  'custom:price_id': string;
  family_name: string;
  email: string;
}

export type IDTokenPayload = ICognitoUser & {
  token_use: string;
  event_id: string;
  auth_time: string;
  exp: number;
  iat: number;
  aud: string;
  iss: string;
  'cognito:username': string;
};
export interface IBrandSetupResponse {
  id: string;
  brand_name: string;
  description: string;
  long_description: string;
  web_page: string;
  brand_url: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  twitter: string;
  instagram: string;
  snapchat: string;
  facebook: string;
  youtube: string;
  pinterest: string;
  weibo: string;
  cover_photo: string;
  intro_video: string;
  outro_video: string;
  meet_cover_photo: string;
  light_logo: string;
  dark_logo: string;
  button_color: string;
  accent_color: string;
  primary_color: string;
  secondary_color: string;
  link_color: string;
  header_font: string;
  header_font_url: string;
  body_font: string;
  body_font_url: string;
  og_image: string;
  countdown_page_background: string;
  meeting_background_landscape?: string;
  meeting_background_portrait?: string;
  parent_brand_id?: string;
}
export interface IBrandSetup {
  id?: string;
  brandName?: string;
  description?: string;
  longDescription?: string;
  webPage?: string;
  brandUrl?: string;
  country?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  meetCoverPhoto?: string;
  coverPhoto?: string;
  meetingBackgroundLandscape?: string;
  meetingBackgroundPortrait?: string;
  ogImage?: string;
  countdownBg?: string;
  video?: {
    intro: string;
    outro: string;
  };
  theme?: {
    logo?: {
      dark?: string;
      light?: string;
    };
    color?: {
      button?: string;
      accent?: string;
      primary?: string;
      secondary?: string;
      link?: string;
    };
    font?: {
      headerFont?: string;
      headerFontUrl?: string;
      bodyFont?: string;
      bodyFontUrl?: string;
    };
  };
  parentBrandId?: string;
}

export interface IAccount {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  phoneCode?: string;
  password?: string;
  jobTitle?: string;
  avatar?: string;
  teams?: string[];
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  contactId?: string;
  brandName?: string;
  space?: string;
  spaceName?: string;
  stripePackage?: string;
  stripeProduct?: string;
  apolloKey?: string;
  hubspotKey?: string;
}

export interface IUserEntitlement {
  id: string;
  context: string;
  uuid: string;
  scope: string;
  scopeDetails: string[];
  roles: IRole[];
}

export interface IRole {
  id: string;
  name: string;
  description: string;
  permissions: IPermission[];
  createdBy: string;
  createdAt: Date;
  modifiedBy: string;
  modifiedAt: Date;
}

export interface IPermission {
  name: string;
  description: string;
}

export enum HUB_USER_PERMISSION {
  PROFILE = 'PROFILE',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  TEAM = 'TEAM',
  BRANDING = 'BRANDING',
  CALENDAR = 'CALENDAR',
  ROOM = 'ROOM',
  NUDGE_TEMPLATE = 'NUDGE_TEMPLATE',
  NUDGE = 'NUDGE',
  STORYBOOK = 'STORYBOOK',
  SCENE = 'SCENE',
  SCENE_TEMPLATE = 'SCENE_TEMPLATE',
  ASSET_LIBRARY = 'ASSET_LIBRARY',
  GALLERY = 'GALLERY',
  PRODUCT = 'PRODUCT',
  STORIES = 'STORIES',
  DEVICES_MANAGEMENT = 'DEVICES',
  DEVICES_MONITORING = 'DEVICE_MONITORING'
}

export interface IConvertedFiles {
  key: string;
  fileBucket: string;
  files: string[];
  metadata: {
    converted: string;
  } & Record<string, string | boolean | number>;
}

export interface IAsset {
  id: string;
  title?: string;
  brand?: string;
  originalURL: string;
  convertedURL?: string;
  assetPreviewURL?: string;
  visibility?: 'private' | 'brand';
  visibilityScope?: string[];
  categories?: string[];
  tags?: string[];
  createdBy?: string;
  createdAt?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  activatedAt?: string;
  activatedBy?: string;
  assetType: string;
  sizeKB?: number;
  status?: 'activated' | 'deleted' | 'private' | 'permanently_deleted';
}

export type GetHubspotContactsParams = {
  after?: number;
  limit?: number;
};

export type HubspotContact = {
  archived: boolean;
  createdAt: string;
  id: string;
  properties: Record<string, any>;
  updatedAt: string;
};

export type HubspotContactResult = {
  paging: {
    next?: {
      after?: string;
      link?: string;
    };
  };
  results: HubspotContact[];
};

export type SBInsightOverview = {
  storybookId?: string;
  shared?: number;
  totalEyeballTime?: number;
  uniqueUsers?: number;
  returningUsers?: number;
  startDate?: string;
  endDate?: string;
  campaign?: string;
  overviewData?: OverviewData;
  dataByDateAndCountry?: DataByDateAndCountry;
  deviceDistribution?: DeviceDistribution;
  source?: DeviceDistribution;
  medium?: DeviceDistribution;
  topScenes?: any[];
  topInteractions?: any[];
  viewsDensity?: { '2023-12-18'?: number[] };
};

export type DataByDateAndCountry = {
  targeted?: { [key: string]: number | string };
  delivered?: { [key: string]: number | string };
  opened?: Engaged;
  engaged?: Engaged;
  interest?: { [key: string]: number | string };
  conversion?: { [key: string]: number | string };
};

export type Engaged = {
  [key: string]: { [key: string]: number };
};

export type DeviceDistribution = {
  [key: string]: Other;
};

export type Other = {
  [key: string]: number;
};

export type OverviewData = {
  targeted?: EngagedClass;
  delivered?: EngagedClass;
  opened?: EngagedClass;
  engaged?: EngagedClass;
  interest?: EngagedClass;
  conversion?: EngagedClass;
};

export type EngagedClass = {
  previousPhase?: number;
  currentPhase?: number;
};

export type SBInsightSceneOverview = {
  storybookId?: string;
  shared?: number;
  totalEyeballTime?: number;
  uniqueUsers?: number;
  returningUsers?: number;
  startDate?: Date;
  endDate?: Date;
  campaign?: string;
  overviewData?: OverviewData;
  scenes?: Scene[];
};

export type Conversion = {
  previousPhase?: number;
  currentPhase?: number;
};

export type Scene = {
  scene?: string;
  totalViews?: number;
  uniqueViews?: number;
  averageEyebalTime?: number;
  clicks?: number;
  shares?: number;
};

export type SBInsightInteractions = {
  storybookId?: string;
  shared?: number;
  totalEyeballTime?: number;
  uniqueUsers?: number;
  returningUsers?: number;
  startDate?: Date;
  endDate?: Date;
  campaign?: string;
  overviewData?: OverviewData;
  interactions?: Interaction[];
};

export type Interaction = {
  totalClicks?: number;
  userCount?: number;
  type?: string;
  title?: string;
  sceneId?: string;
};

export type IDevice = {
  applicationInventoryId: string;
  brand: string;
  description: string;
  lensBarConfigurationId: string;
  deviceId: string;
  deviceType: 'SSP' | 'Lense';
  version: string;
  lastOnlineTime: string;
  name: string;
  productCount: number;
  storeId: string;
};

export type IDeviceComments = {
  comment: string;
  commentedAt: string;
  commentedBy: string;
};
export type IDeviceStatus = {
  status: 'ONLINE' | 'OFFLINE' | 'AVAILABLE' | 'UNAVAILABLE' | undefined;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  deviceId: string;
  name: string;
  storeCity: string;
  storeCountry: string;
  storeId: string;
  storeName: string;
  brandName: string;
  brand: string;
  comments: IDeviceComments[];
};

export type IHubComment = {
  comment: string;
  createdAt: string;
  createdBy: IUser;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  referenceId: string;
  type: string;
};

export type IDeviceProductSlots = {
  productId: string;
  slot: number;
};

export type IDeviceConfiguration = {
  id: string;
  name: string;
  rateLimit: number;
  offlineModeEnabled: boolean;
  entertainmentModeIdleTime: number;
  beacon_uuid: string;
  beacon_minor: string;
  colourScheme: 'DARK' | 'LIGHT';
  layout: {
    slots_x_axis: number;
    slots_y_axis: number;
  };
  productSlots: IDeviceProductSlots[];
  showcaseVideos: string[];
  scenes: ISceneDetail[] | string[];
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  description: string;
  deviceId: string;
};

export interface ISceneDetail extends ILibraryScene {
  durationInSec?: string;
  rule?: string;
  isDefaultScene?: boolean;
  sceneId?: string;
}

export type DeviceOverviewData = {
  productScanned?: EngagedClass;
  productViewed?: EngagedClass;
  productShared?: EngagedClass;
  storyViewed?: EngagedClass;
  storyScanned?: EngagedClass;
  storyShared?: EngagedClass;
};

export type DeviceDataByDateAndCountry = {
  productScanned?: { [key: string]: number | string };
  productViewed?: { [key: string]: number | string };
  productShared?: { [key: string]: number | string };
  storyViewed?: { [key: string]: number | string };
  storyScanned?: { [key: string]: number | string };
  storyShared?: { [key: string]: number | string };
};

export type DeviceInsightOverview = {
  userId?: string;
  topProducts?: any[];
  topStories?: any[];
  startDate?: string;
  endDate?: string;
  overviewData?: DeviceOverviewData;
  viewsDensity?: DeviceDistribution;
  medium?: DeviceDistribution;
  deviceDistribution?: DeviceDistribution;
  source?: DeviceDistribution;
  dataByDateAndCountry?: DeviceDataByDateAndCountry;
};

export type RuleId = 'GENDER' | 'DISTANCE' | 'AGE GROUP' | 'EMOTION';

export type SceneRule = {
  id: RuleId;
  values: string[];
};
